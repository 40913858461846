import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppConfigService } from 'services/app-config.service';
import { ResourceService } from 'services/resource.service';
import { SseService } from 'services/sse.service';
import { TabService } from 'services/tab.service';
import { ThemeService } from 'services/theme.service';
import { AppState, TabState } from 'src/app/app.state';
import { ROUTE_PATHS } from 'src/app/models/constants/route-paths.constants';
import { selectTabs } from 'src/app/store/selectors/tab.selectors';
import { ITEMS } from './model/items';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  isSideMenuOpen = true;
  displayBreadcrumb = true;
  rootUrl: string;

  isExpanded: boolean = true;

  logPath: string;
  trademark = {
    telcoTheme: 'assets/logos/telcoTheme.png',
    telco: 'assets/images/favicon.png',
    albisElconTheme: `assets/theme/albisElconTheme/albisElconTheme.png`,
    aconnicTheme: `assets/theme/aconnicTheme/aconnicTheme.png`,

  };

  tabs$: Observable<TabState[]>

  @ViewChild('toolbarRef', { read: ElementRef }) toolbarRef;

  
  @ViewChild('drawer', { static: false }) public drawer: MatDrawer;


  readonly items = ITEMS.map(obj => ({ ...obj })).filter(x => {
    if (x.children) {
      x.children = x.children?.filter(z => this.readOnlyXServiceDesigner(z));
    }
    return this.readOnlyXAdministrations(x);
  });

  private breadCrumbRouters = [
    'Edge%20Management',
    'Troubleshoot',
    'Alarms',
    'VPN%20Management',
    'Discovery',
    'Administration',
    'Service%20Management',
    'Controller%20Management',
    'Application%20Management',
    'Notifications'
  ];

  constructor(
    private cookies: CookieService,
    private router: Router,
    private tabService: TabService,
    public resourceService: ResourceService,
    public themeService: ThemeService,
    private config: AppConfigService,
   private sseService: SseService,
    // eslint-disable-next-line @ngrx/no-typed-global-store
    private store: Store<AppState>
  ) {
  }

  readOnlyXServiceDesigner(x) {
    return !(x.name == 'Service Designer' && this.cookies.get('is_readonly') == 'true');
  }

  readOnlyXAdministrations(x) {
    return !(x.name == 'Administration' && this.cookies.get('role_id') == '2');
  }

  ngOnInit(): void {
    this.tabs$ = this.store.select((selectTabs));
    
    this.router.events.pipe(filter(event => event instanceof ActivationEnd)).subscribe(
      () => {
        this.breadcrumb();
        this.redirectSelection();
      });
    this.breadcrumb();

    this.logPath = this.trademark[this.themeService.theme] || this.trademark.telco;
    this.logPath = this.config?.config?.theme ? `assets/theme/${this.config.config.theme}/${this.config.config.theme}.svg` : this.trademark.telco;

    this.sseService.initEventSourceSingleton();
    this.themeService.primary.subscribe(value => {
      this.toolbarRef.nativeElement.style.setProperty('--primary', value);
    });
    this.themeService.image.pipe(filter(x => x != '1')).subscribe(value => {
      this.logPath = value || this.trademark[this.themeService.theme] || this.trademark.telco;
    });

  }
  
  ngOnDestroy(): void {
    this.items.forEach(z => z.showSubmenu = false);
    this.items.forEach(z => {
      z.theChosen = false;
      z.children?.forEach(x => x.theChosenChild = false);
    });
  }

  openTab(title: string, path: string) {
    this.tabService.openTab(title, path);
  }

  redirectSelection() {
    if (this.router.url === '/Kubernetes%20Service%20Management/ServiceTemplateCatalog/ServiceTemplateCatalog') {
      const serviceManagementItem = this.items.find(el => el.name == 'Kubernetes Service Management');
      if (serviceManagementItem) {
        const child = serviceManagementItem.children?.find(el => el.name === 'Service Templates Catalog');
        if (child && child['theChosenChild'] === false) {
          this.onItemChildren(serviceManagementItem, child);
        }
      }
    }
  }

  breadcrumb() {
    this.rootUrl = this.router.url.split('/')[1];
    if (this.breadCrumbRouters.includes(this.rootUrl)) {
      this.displayBreadcrumb = true;
    }
    if (this.rootUrl === 'Dashboard') {
      this.displayBreadcrumb = false;
    } else this.displayBreadcrumb = true;
  }

  onItemSelected(item) {
    // this.items.forEach(x => x.theChosen = false);
    // item.theChosen = true;
    if (item.children) {
      item.showSubmenu = !item.showSubmenu;
    } else{
      this.openTab(item.name, item.routerLink);
    }
    
    if (!this.isSideMenuOpen && item.children) {
      this.isSideMenuOpen = true;
    }
    if(!this.isExpanded){ 
        this.openTab(item.name, item.routerLink);
    }
  }

  onItemChildren(parent, child) {
    this.items.forEach(z => {
      z.theChosen = false;
      z.children?.forEach(x => x.theChosenChild = false);
    });

    parent.theChosen = true;
    child.theChosenChild = true;
    this.openTab(child.name, child.routerLink);
  }

  navigateToDashboard(): void {
    const title = ROUTE_PATHS.DASHBOARD.title;
    const path = ROUTE_PATHS.DASHBOARD.route;
    this.tabService.openTab(title, path);
  }
}