import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as echarts from 'echarts';
import { ECharts } from 'echarts';
import { TabService } from 'services/tab.service';
import { colors } from '../../../models';
import { DASHBOARD_ROUTE_CONFIGS } from '../models/dashboard-route-path.constants';

interface Params {
  name: string;
  selected: object;
  type: string;
}

@Component({
  selector: 'app-admin-status-chart',
  templateUrl: './admin-status-chart.component.html',
  styleUrls: ['./admin-status-chart.component.scss']
})
export class AdminStatusChartComponent implements OnChanges {

  @Input() adminStatusData: any;
  @ViewChild('adminStatusChartSelector', { read: ElementRef, static: false }) adminStatusChartSelector: ElementRef;

  public adminStatusChart: any;
  public colors: typeof colors = colors;
  myChart: ECharts;
  selectedLegend: object;
  isSelectedCountLegend: boolean = true;

  constructor(private router: Router,private tabService:TabService) { }

  ngOnChanges(): void {
    if (this.adminStatusData != null) {
      if (this.isSelectedCountLegend) {
        this.initChart();
      }
    }
  }

  convertToNameArray(data) {
    const array = new Array<string>();
    Object.keys(data).sort().forEach(key => {
      array.push(key.charAt(0).toUpperCase() + key.slice(1) + ' ')  //.replace(/ /gi,'\n')
    })
    return array
  }

  getValueArray(data) {
    const array = new Array<string>();
    Object.keys(data).sort().forEach(key => {

      array.push(data[key])
    })
    return array
  }

  initChart() {
    this.adminStatusChart = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // Use axis to trigger tooltip
          type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        },
        textStyle: {
          color: colors.TEXT,
          fontFamily: colors.FONT,
        }
      },
      legend: {
        textStyle: {
          color: colors.TEXT,
          fontFamily: colors.FONT,
        }
      },
      grid: {
        left: '3%',
        right: '15%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        show: false,
        type: 'value',
        max: 'dataMax',
        minInterval: 1,
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        }
      },
      yAxis: {
        show: Object.keys(this.adminStatusData).length === 0 ? false : true,
        type: 'category',
        data: this.convertToNameArray(this.adminStatusData),
        axisTick: {
          show: false
        }
      },
      series: [
        {
          name: 'Count',
          type: 'bar',
          stack: 'total',
          clip: false,
          label: {
            show: true,
            position: 'right',
            valueAnimation: true,
            color: colors.TEXT,
            fontFamily: colors.FONT,
          },
          itemStyle: {
            // Color of the point.
            color: colors.BLUE
          },
          emphasis: {
            //  focus: 'none'
          },
          data: this.getValueArray(this.adminStatusData)
        }
      ]
    };

    if (this.myChart === undefined) {
      this.myChart = echarts.init(this.adminStatusChartSelector.nativeElement);
      this.myChart.on('legendselectchanged', (params: Params) => {
        if (params.name === 'Count') {
          this.isSelectedCountLegend = !this.isSelectedCountLegend;
        }
      })
      this.myChart.on('click', function (params) {
        const title = DASHBOARD_ROUTE_CONFIGS.ADMIN_STATUSES_EDGES.title;
        const path = DASHBOARD_ROUTE_CONFIGS.ADMIN_STATUSES_EDGES.route.replace(':name', params.name.slice(0, -1).toString()).replace(':id','chartName');
        this.tabService.openTab(title, path);
      }.bind(this));
    }
  }
}