import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState, TabState } from '../app.state';
import { ROUTE_CONFIGS, ROUTE_PATHS } from '../models/constants/route-paths.constants';
import * as TabActions from '../store/actions/tab.actions';
import { FormGroup } from '@angular/forms';
import { AlertNotificationService } from './alertNotification.service';

@Injectable({
  providedIn: 'root',
})
export class TabService {
  private tabs: TabState[];
  private tabHistory: string[] = [];

  private mainRoutes = new Set(Object.values(ROUTE_CONFIGS).map(route => route.route));
  private idForms = new Map<string, FormGroup>([]);

  // eslint-disable-next-line @ngrx/no-typed-global-store
  constructor(private store: Store<AppState>, private router: Router, private alertNotificationService: AlertNotificationService) {
    // eslint-disable-next-line @ngrx/no-store-subscription, @ngrx/prefer-selector-in-select
    this.store.select(state => state.tabs).subscribe(tabs => this.tabs = tabs);
  }

  // openTab(title: string, path: string) {
  //   const id = crypto.randomUUID();
  //   const component = '';
  //   this.store.dispatch(TabActions.openTab({ id, title, path, component }));
  //   this.tabHistory.push(id);
  //   this.router.navigate([`/home/${id}/${path}`]);
  // }

  get tabsCount() {
    return this.tabs.length;
  }

  get getLastActiveTab() {
    return this.tabs.length > 0 ? this.tabs.find(tab => tab.isActive == true) : null;
  }

  setFormById(id: string, formGroup: FormGroup) {
    this.idForms.set(id, formGroup);
  }

  getFormById(id: string) {
    return this.idForms.get(id);
  }

  deleteFormById(id: string) {
    return this.idForms.delete(id);
  }

  openTab(title: string, path: string) {
    let currentActiveId = this.tabs.find(tab => tab.isActive)?.id;

    // Check if the path is one of the main routes
    if (this.mainRoutes.has(path)) {
      const existingTab = this.tabs.find(tab => tab.path === path);
      currentActiveId = '';
      // If the tab is already open, switch to it instead of opening a new one
      if (existingTab) {
        this.switchTab(existingTab.id);
        return;
      }
    }

    if (this.tabs.length > 14) {
      this.alertNotificationService.alertInfo(`Limit of 15 open tabs is reached. You need to close at least one tab to open a new one.`);
      return;
    }

    // If the tab is not open, create a new tab
    const id = crypto.randomUUID();
    const component = '';
    this.store.dispatch(TabActions.openTab({ id, title, path, component, currentActiveId }));
    this.tabHistory.push(id);
    this.router.navigate([`/home/${id}/${path}`]);
  }

  closeTab(id: string, params?: { route?: string; title?: string }) {
    this.store.dispatch(TabActions.closeTab({ id }));
    this.tabHistory = this.tabHistory.filter(tabId => tabId !== id);

    if (params?.route) {
      const baseRoute = params.route.split('/').slice(2).join('/');
      const matchingTab = this.tabs.find(tab => tab.path === baseRoute);
      if (matchingTab) {
        this.switchTab(matchingTab.id);
      } else {
        this.openTab(params.title, params.route);
      }
    } else if (this.tabHistory.length > 0) {
      const previousTabId = this.tabHistory[this.tabHistory.length - 1];
      this.switchTab(previousTabId);
    } else if (this.tabs.length == 0) {
      this.logoutUser();
    } else {
      // Default route or fallback action

    }
  }

  switchTab(id: string) {
    this.store.dispatch(TabActions.switchTab({ id }));
    const activeTab = this.tabs.find(tab => tab.id === id);
    if (activeTab) {
      this.router.navigate([`/home/${activeTab.id}/${activeTab.path}`]);
    }
  }

  updateTabData(id: string, data: any) {
    this.store.dispatch(TabActions.updateTabData({ id, data }));
  }

  getTabData(id: string): any {
    const tab = this.tabs.find(tab => tab.id === id);
    return tab ? tab.data : {};
  }

  logoutUser(): void {
    const title = ROUTE_PATHS.DASHBOARD.title;
    const path = ROUTE_PATHS.DASHBOARD.route;
    this.openTab(title, path);

    this.store.dispatch(TabActions.logout());
    this.tabHistory = [];
    this.idForms.clear();

  }

  getAllTabs() {
    return this.tabs;
  }
}