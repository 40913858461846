import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import * as echarts from 'echarts';
import { ECharts } from 'echarts';

import { Router } from '@angular/router';
import { TabService } from 'services/tab.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { AlarmsChartData, colors } from '../../../models';
import { DASHBOARD_ROUTE_CONFIGS } from '../models/dashboard-route-path.constants';

@Component({
  selector: 'app-alarms-chart',
  templateUrl: './alarms-chart.component.html',
  styleUrls: ['./alarms-chart.component.scss']
})
export class AlarmsChartComponent implements AfterViewInit, OnChanges {
  @ViewChild('alarmChartSelector', { read: ElementRef, static: false }) edgeChartSelector: ElementRef;
  @Input() alarmCharData: AlarmsChartData;

  colors: typeof colors = colors;
  isExpanded: boolean = false;
  stopRecalculteOnRefresh: boolean = false;
  alarmChart: any;
  myChart: ECharts;
  vnfTypesSum = 0;
  selectedLegend: object;

  constructor(private router: Router, private breadcrumbService: BreadcrumbService, private tabService: TabService) { }

  ngOnChanges(): void {
    if (this.alarmCharData != null) {
      this.initChart()

      // if(this.selectedLegend!=null){
      //   //TODO: researh how to set legend selected
      //   // this.myChart.setOption({legend:{selected:this.selectedLegend,selectedMode: true}})        
      // }      
    }
  }

  ngAfterViewInit(): void {
    this.myChart = echarts.init(this.edgeChartSelector.nativeElement);
    this.myChart.on('legendselectchanged', function (params) {

      if (params['selected']) {
        this.selectedLegend = params['selected'];
        this.vnfTypesSum = 0;
        for (const legentItem in params['selected']) {
          if (params['selected'][legentItem]) {
            if (this.alarmCharData[legentItem] != undefined) {
              this.vnfTypesSum = this.vnfTypesSum + this.alarmCharData[legentItem]
            }
          }
        }
      }

      this.myChart.setOption({
        title: {
          text: this.vnfTypesSum,
          left: this.recenter(this.vnfTypesSum),
          top: "center",
          textStyle: {
            fontSize: 16,
            align: 'center',
            color: colors.TEXT,
            fontFamily: colors.FONT,
          }
        }
      }
      );
    }.bind(this));

    this.resizeChart();
    this.myChart.on('click', function (params) {
      //TODO: GO TO REVILABLE TABLE WITH SORTING
      this.breadcrumbService.set(`Notifications/EdgeEvents/${params.name}//0/true`, 'Severity   /   ' + params.name);

      const title = DASHBOARD_ROUTE_CONFIGS.EDGE_EVENTS.title;
      const path = DASHBOARD_ROUTE_CONFIGS.EDGE_EVENTS.route.replace(':severity', params.name.toString()).replace(':nodeSerial', ' ').replace(':nodeId', '0').replace(':activeOnly', 'true');
      this.tabService.openTab(title, path);
    }.bind(this));
  }

  calculateInit(edgeData: any): number {
    const keysOfInterest = [
      'Warning', 'Minor', 'Major', 'Critical', 'Normal',
      'Info'
    ];

    return keysOfInterest.reduce((acc, key) => {
      return acc + (edgeData[key] || 0);
    }, 0);
  }

  recenter(value: number) {
    const thresholds = [
      { max: 10, percent: '22.5%' },
      { max: 100, percent: '21.5%' },
      { max: 1000, percent: '20.5%' },
      { max: 10000, percent: '19.5%' },
      { max: 100000, percent: '18.5%' },
      { max: 1000000, percent: '17.5%' }
    ];

    for (const threshold of thresholds) {
      if (value < threshold.max) {
        return threshold.percent;
      }
    }
    return '17.5%'; // Default value return
  }

  convertNameValueArray(chartData: AlarmsChartData) {
    const statusColorMap = {
      Warning: { name: 'Warning', color: colors.YELLOW },
      Minor: { name: 'Minor', color: colors.PINK },
      Major: { name: 'Major', color: colors.ORANGE },
      Critical: { name: 'Critical', color: colors.RED },
      Info: { name: 'Info', color: colors.BLUE },
      Normal: { name: 'Normal', color: colors.GREEN },
    };

    const newArrayData = Object.keys(statusColorMap)
      .filter(status => chartData[status])
      .map(status => ({
        name: statusColorMap[status].name,
        value: chartData[status],
        itemStyle: { color: statusColorMap[status].color }
      }));

    this.resizeChart();
    return newArrayData;
  }

  public initChart(): void {
    let newChartData: AlarmsChartData = new AlarmsChartData();

    if (this.selectedLegend) {
      for (const key in this.selectedLegend) {
        if (this.selectedLegend[key]) {
          const systemstatus = key
          newChartData[systemstatus] = this.alarmCharData[systemstatus]
        }
      }
    }
    else {
      newChartData = this.alarmCharData;
    }

    const initCount = this.calculateInit(newChartData)

    this.alarmChart = {
      title: {
        text: initCount,
        left: this.recenter(initCount),
        top: "center",
        textStyle: {
          fontSize: 16,
          align: 'center',
          color: colors.TEXT,
          fontFamily: colors.FONT,

        }
      },
      tooltip: {
        trigger: 'item',
        textStyle: {
          color: colors.TEXT,
          fontFamily: colors.FONT,
        }
      },
      legend: {
        top: 'center',
        left: 250,
        data: [{ name: 'Critical', itemStyle: { color: colors.RED } }, { name: 'Major', itemStyle: { color: colors.ORANGE } }, { name: 'Minor', itemStyle: { color: colors.YELLOW } }, { name: 'Warning', itemStyle: { color: colors.YELLOW } },
        { name: 'Info', itemStyle: { color: colors.BLUE } }, { name: 'Normal', itemStyle: { color: colors.GREEN } }],
        textStyle: {
          color: colors.TEXT,
          fontFamily: colors.FONT,
        }
      },
      series: [{
        type: 'pie',
        radius: ['45%', '90%'],
        center: ['25%', '50%'],
        label: {
          show: true,
          position: 'inner',
          color: colors.TEXT,
          fontFamily: colors.FONT,
          formatter: (params) => {
            return params.value;
          }
        },
        labelLine: {
          normal: {
            show: false,
            //   position: 'inner',
            //   formatter: (params) => {
            //       return 10;
            //   }

          }
        },
        hoverAnimation: false,
        avoidLabelOverlap: false,

        itemStyle: {
          borderRadius: 5,
          borderColor: '#fff',
          // borderWidth: 1
        },

        data: this.convertNameValueArray(this.alarmCharData)

      }]
    };

    if (this.selectedLegend != null) {
      this.alarmChart['legend']['selected'] = this.selectedLegend;
      this.alarmChart['legend']['selectedMode'] = true;
      this.stopRecalculteOnRefresh = true

    }

  }

  onChartClick() {
    //console.log(event.name)
  }

  resizeChart() {
    if (this.myChart) {
      this.myChart.resize({ width: 382, height: 209 });
    }
  }
}