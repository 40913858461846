import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as TabActions from '../actions/tab.actions';

@Injectable()
export class TabEffects {
  constructor(private actions$: Actions) {}

  loadTabData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TabActions.openTab),
      mergeMap(action =>
        of({}).pipe(
          map(data => TabActions.updateTabData({ id: action.id, data })),
          catchError(() => of({ type: '[Tab API] Tab Data Load Failed' }))
        )
      )
    )
  );
}