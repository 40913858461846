import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as echarts from 'echarts';
import { ECharts } from 'echarts';
import { TabService } from 'services/tab.service';
import { ServicesChartData } from 'src/app/models/ServicesChartData';
import { colors } from '../../../../models';
import { DASHBOARD_ROUTE_CONFIGS } from '../../models/dashboard-route-path.constants';
interface Params {
  name: string;
  selected: object;
  type: string;
}

@Component({
  selector: 'telco-deployed-services-chart',
  templateUrl: './deployed-services-chart.component.html',
  styleUrls: ['./deployed-services-chart.component.scss']
})
export class DeployedServicesChartComponent implements AfterViewInit, OnChanges {

  @ViewChild('ServicesChartSelector', { read: ElementRef, static: false }) ServicesChartSelector: ElementRef;
  @Input() vnfTypesData: ServicesChartData;

  public vnfTypeChart: any;
  public colors: typeof colors = colors;
  myChart: ECharts;
  vnfTypesSum: number;
  chartHeight: string = '208px';
  chartMargin: string = '0px';

  isSelectedStartedLegend: boolean = true;
  isSelectedNotStartedLegend: boolean = true;

  constructor(private router: Router, private tabService: TabService) {
    this.vnfTypesSum = 0;
  }

  ngOnChanges(): void {
    if (this.vnfTypesData != null) {
      if (this.isSelectedStartedLegend && this.isSelectedNotStartedLegend) {
        this.calculateBarsWidth(this.vnfTypesData);
        this.initChart();
      }
    }
  }

  calculateBarsWidth(servicesChartData: ServicesChartData) {
    const barsCount = Object.keys(servicesChartData).length
    if (barsCount > 3) {
      const chartHeight = barsCount * 70;
      const chartMargin = chartHeight - 200;
      this.chartHeight = `${chartHeight}px`;
      this.chartMargin = `${chartMargin}px`;
    }
  }

  ngAfterViewInit() {
    if (!this.ServicesChartSelector) return;

    this.myChart = echarts.init(this.ServicesChartSelector.nativeElement);

    this.myChart.on('legendselectchanged', (params: Params) => {
      if (params.name === 'Started') {
        this.isSelectedStartedLegend = !this.isSelectedStartedLegend;
      } else if (params.name === 'Not started') {
        this.isSelectedNotStartedLegend = !this.isSelectedNotStartedLegend;
      }
    });

    this.myChart.on('click', function (params) {

      const title = DASHBOARD_ROUTE_CONFIGS.EDGE_SERVICE_TEMPLATE.title;
      const path = DASHBOARD_ROUTE_CONFIGS.EDGE_SERVICE_TEMPLATE.route.replace(':state', params.seriesName.toString()).replace(':name', params.name.toString());
      this.tabService.openTab(title, path);
    }.bind(this));
  }

  convertToNameArray(vnfTypesData) {
    const array = new Array<string>();

    Object.keys(vnfTypesData).sort().forEach(key => {
      array.push(key.replace(/ /gi, '\n'));
    });

    return array;
  }

  getRunningArray(vnfTypesData) {
    const array = new Array<string>();

    Object.keys(vnfTypesData).sort().forEach(key => {
      array.push(vnfTypesData[key]['started'] == 0 ? undefined : vnfTypesData[key]['started']);
    });

    return array;
  }

  getStoppedArray(vnfTypesData) {
    const array = new Array<string>();

    Object.keys(vnfTypesData).sort().forEach(key => {
      array.push(vnfTypesData[key]['not started'] == 0 ? undefined : vnfTypesData[key]['not started']);
    });

    return array;
  }



  public initChart(): void {
    this.vnfTypeChart = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // Use axis to trigger tooltip
          type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        },
        textStyle: {
          color: colors.TEXT,
          fontFamily: colors.FONT,
        }
      },
      legend: {
        textStyle: {
          color: colors.TEXT,
          fontFamily: colors.FONT,
        }
      },
      grid: {
        left: '3%',
        right: '5%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        show: false,
        type: 'value',
        minInterval: 1,
        axisTick: {
          show: false
        }
      },
      yAxis: {
        show: Object.keys(this.vnfTypesData).length === 0 ? false : true,
        type: 'category',
        data: this.convertToNameArray(this.vnfTypesData),
        axisTick: {
          show: false
        },
        nameTextStyle: {
          color: colors.TEXT,
          fontFamily: colors.FONT,
        }
      },

      series: [
        {
          name: 'Started',
          type: 'bar',
          stack: 'total',
          clip: false,
          label: {
            show: true,
            color: colors.TEXT,
            fontFamily: colors.FONT,
          },
          itemStyle: {
            // Color of the point.
            color: colors.GREEN
          },
          emphasis: {
            //  focus: 'none'
          },
          data: this.getRunningArray(this.vnfTypesData),
          // barWidth: '30%',
          barCategoryGap: '30%',
          barWidth: 23
        },
        {
          name: 'Not started',
          type: 'bar',
          stack: 'total',
          itemStyle: {
            // Color of the point.
            color: colors.RED
          },
          clip: false,
          label: {
            show: true,
            color: colors.TEXT,
            fontFamily: colors.FONT,
          },
          emphasis: {
            // focus: 'none'//'series'
          },
          data: this.getStoppedArray(this.vnfTypesData),
          // barWidth: '30%',
          barCategoryGap: '30%',
          barWidth: 23
        },
      ]
    };
  }

  resizeChart() {
    if (this.myChart) {
      this.myChart.resize({ width: 432, height: 209 });
    }
  }
}
