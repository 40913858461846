export const ROUTE_PATHS = {
  DASHBOARD: { title: 'Dashboard', route: '/Dashboard' },
  EDGE_MANAGEMENT: { title: 'Edge Management', route: '/Edge Management' },
  CONTROLLER_MANAGEMENT: { title: 'Controller Management', route: '/Controller Management' },
  VPN_MANAGEMENT: { title: 'VPN Management', route: '/VPN Management' },
  APPLICATION_MANAGEMENT: { title: 'Application Management', route: '/Application Management' },
  KUBERNETES_MANAGEMENT: { title: 'Kubernetes Service Management', route: '/Kubernetes Service Management' },
  OPENSTACK_MANAGEMENT: { title: 'Openstack Service Management', route: '/Openstack Service Management' },
  LICENSE_MANAGEMENT: { title: 'License Management', route: '/License Management' },
  NOTIFICATIONS: { title: 'Notifications', route: '/Notifications' },
  DISCOVERY: { title: 'Discovery', route: '/Discovery' },
  ADMINISTRATION: { title: 'Administration', route: '/Administration' }
};

export const ROUTE_CONFIGS = {
  ...ROUTE_PATHS,
  // Edge Routes
  NAMESPACES: { title: 'Namespaces', route: `${ROUTE_PATHS.KUBERNETES_MANAGEMENT.route}/Namespaces` },
  HELM: { title: 'Helm Charts', route: `${ROUTE_PATHS.KUBERNETES_MANAGEMENT.route}/HelmCharts` },
  EDGE_DEVICES: { title: 'Devices', route: `${ROUTE_PATHS.EDGE_MANAGEMENT.route}/EdgeDevices` },
  OPERATORS: { title: 'Operators', route: `${ROUTE_PATHS.EDGE_MANAGEMENT.route}/Operators` },
  CUSTOMERS: { title: 'Customers', route: `${ROUTE_PATHS.EDGE_MANAGEMENT.route}/Customers` },
  SERVICES: { title: 'Services', route: `${ROUTE_PATHS.EDGE_MANAGEMENT.route}/Services` },
  SITES: { title: 'Sites', route: `${ROUTE_PATHS.EDGE_MANAGEMENT.route}/Sites` },
  HARDWARE_TYPES: { title: 'Hardware Types', route: `${ROUTE_PATHS.EDGE_MANAGEMENT.route}/HWTypes` },
  EDGE_TEMPLATES: { title: 'Templates', route: `${ROUTE_PATHS.EDGE_MANAGEMENT.route}/EdgeTemplates` },
  // Controller Routes
  CONTROLLERS: { title: 'Controllers', route: `${ROUTE_PATHS.CONTROLLER_MANAGEMENT.route}/Controllers` },
  // VPN Routes
  VPN_CONCENTRATORS: { title: 'Concentrators', route: `${ROUTE_PATHS.VPN_MANAGEMENT.route}/Concentrators` },
  // Application Routes
  APPLICATION_CATALOG: { title: 'Applications Catalog', route: `${ROUTE_PATHS.APPLICATION_MANAGEMENT.route}/ApplicationsCatalog` },
  APPLICATION_TEMPLATES_CATALOG: { title: 'Service Templates Catalog', route: `${ROUTE_PATHS.APPLICATION_MANAGEMENT.route}/ApplicationTemplates` },
  APPLICATION_EDGE_SERVICES: { title: 'Edge Services', route: `${ROUTE_PATHS.APPLICATION_MANAGEMENT.route}/ApplicationsManage` },
  APPLICATION_DESIGNER: { title: 'Service Designer', route: `${ROUTE_PATHS.APPLICATION_MANAGEMENT.route}/ServiceDesign` },
  // Kubernetes Routes
  KUBERNETES_CATALOG: { title: 'Images Catalog', route: `${ROUTE_PATHS.KUBERNETES_MANAGEMENT.route}/ServiceImageCatalog` },
  KUBERNETES_TEMPLATES_CATALOG: { title: 'Service Templates Catalog', route: `${ROUTE_PATHS.KUBERNETES_MANAGEMENT.route}/ServiceTemplateCatalog` },
  KUBERNETES_BACKUP_PROFILES: { title: 'Backup Profiles', route: `${ROUTE_PATHS.KUBERNETES_MANAGEMENT.route}/BackupProfiles` },
  KUBERNETES_BACKUP_HISTORY: { title: 'Backup History', route: `${ROUTE_PATHS.KUBERNETES_MANAGEMENT.route}/BackupHistory` },
  KUBERNETES_RESTORE_BACKUPS: { title: 'Restore Backups', route: `${ROUTE_PATHS.KUBERNETES_MANAGEMENT.route}/RestoreHistory` },
  KUBERNETES_EDGE_SERVICES: { title: 'Edge Services', route: `${ROUTE_PATHS.KUBERNETES_MANAGEMENT.route}/DeployedServices` },
  KUBERNETES_DESIGNER: { title: 'Service Designer', route: `${ROUTE_PATHS.KUBERNETES_MANAGEMENT.route}/ServiceDesign` },
  // Openstack Routes
  OPENSTACK_CATALOG: { title: 'Images Catalog', route: `${ROUTE_PATHS.OPENSTACK_MANAGEMENT.route}/OpenstackImageCatalog` },
  OPENSTACK_FLAVOR_CATALOG: { title: 'Flavors Catalog', route: `${ROUTE_PATHS.OPENSTACK_MANAGEMENT.route}/OpenstackFlavorCatalog` },
  OPENSTACK_NETWORKS_CATALOG: { title: 'Networks Catalog', route: `${ROUTE_PATHS.OPENSTACK_MANAGEMENT.route}/OpenstackNetworkCatalog` },
  OPENSTACK_TEMPLATES_CATALOG: { title: 'Templates Catalog', route: `${ROUTE_PATHS.OPENSTACK_MANAGEMENT.route}/OpenstackTemplateCatalog` },
  OPENSTACK_EDGE_SERVICES: { title: 'Edge Services', route: `${ROUTE_PATHS.OPENSTACK_MANAGEMENT.route}/OpenstackServices` },
  OPENSTACK_DESIGNER: { title: 'Service Designer', route: `${ROUTE_PATHS.OPENSTACK_MANAGEMENT.route}/OpenstackServiceDesign` },
  // License Routes
  LICENSE_LICENSES: { title: 'Licenses', route: `${ROUTE_PATHS.LICENSE_MANAGEMENT.route}/Licenses` },
  LICENSE_SUMMARY: { title: 'Summary', route: `${ROUTE_PATHS.LICENSE_MANAGEMENT.route}/Summary` },
  // Notifications Routes
  NOTIFICATIONS_EDGE_EVENTS: { title: 'Edge Events', route: `${ROUTE_PATHS.NOTIFICATIONS.route}/EdgeEvents` },
  NOTIFICATIONS_HELM_EVENTS: { title: 'Helm Chart Events', route: `${ROUTE_PATHS.NOTIFICATIONS.route}/HelmChartEvents` },
  NOTIFICATIONS_CONTROLLER_EVENTS: { title: 'Controller Events', route: `${ROUTE_PATHS.NOTIFICATIONS.route}/ControllerEvents` },
  NOTIFICATIONS_KUBERNETES_SERVICE_EVENTS: { title: 'Kubernetes Service Events', route: `${ROUTE_PATHS.NOTIFICATIONS.route}/ServiceEvents` },
  NOTIFICATIONS_OPENSTACK_SERVICE_EVENTS: { title: 'Openstack Service Events', route: `${ROUTE_PATHS.NOTIFICATIONS.route}/OpenstackServiceEvents` },
  NOTIFICATIONS_SYSTEM_EVENTS: { title: 'System Events', route: `${ROUTE_PATHS.NOTIFICATIONS.route}/SystemEvents` },
  NOTIFICATIONS_USER_OPERATIONS: { title: 'User Operations', route: `${ROUTE_PATHS.NOTIFICATIONS.route}/UserOperations` },
  NOTIFICATIONS_ALERTS: { title: 'Alerts', route: `${ROUTE_PATHS.NOTIFICATIONS.route}/Alerts` },
  // Discovery Routes
  DISCOVERY_EDGE_DEVICES: { title: 'Edge Discovery', route: `${ROUTE_PATHS.DISCOVERY.route}/DiscoveryEdges` },
  DISCOVERY_CONTROLLERS: { title: 'Controller Discovery', route: `${ROUTE_PATHS.DISCOVERY.route}/DiscoveryControllers` },
  // Administration Routes
  ADMINISTRATION_PROJECTS: { title: 'Projects', route: `${ROUTE_PATHS.ADMINISTRATION.route}/Projects` },
  ADMINISTRATION_SYSTEM_MONITORING: { title: 'System Monitoring', route: `${ROUTE_PATHS.ADMINISTRATION.route}/SystemMonitoring` },
  ADMINISTRATION_USERS: { title: 'Users', route: `${ROUTE_PATHS.ADMINISTRATION.route}/UsersManagement` },
};
