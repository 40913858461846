import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/';
import { map, tap } from 'rxjs/operators';
import { ResourceService } from 'services/resource.service';

export interface FilterData {
  count: number;
  data: [];
  id: number;
  name: string;
  type: string;
  user_id: number;
}

@Injectable({
  providedIn: 'root'
})
export class AdvancedFilterService {
  appliedFilterName: string;
  operStatusEdge: string[] = [ 'configuring', 'running', 'starting', 'unreachable', 'rebooting', 'degraded', 'upgrading', 'not contacted', 'stopping', 'unlicensed' ];
  categories: string[] = [ 'Info', 'Warning', 'Normal', 'Critical' ];
  userStatusEdge = [ 'Active', 'Not Active' ];
  statusesUser = [ 'Failed', 'Completed', 'Pending', 'In progress', 'Canceled' ];
  is_ackItems = [ 'Not acknowledged', 'Acknowledged' ];
  groups: string[] = [ 'Administrator', 'Operator', 'Customer' ];
  bools: string[] = [ 'true', 'false' ];
  blacklisted: string[] = [ 'true', 'false' ];
  filterData: FilterData;

  constructor(
    private resourceService: ResourceService,
    private http: HttpClient,
    private router: Router,
  ) {
  }

  findAppliedFilter() {
    const filterLocation = this.router.url.split('/')[this.router.url.split('/').length - 1];

    return this.http.get<FilterData>(`${this.resourceService.getApiUrl()}/user/data?type=${filterLocation}`)
      .pipe(map((res) => res.data.filter(filter => (filter as any).data.applied)));
  }

  getDashboardFilter(type: string): Observable<any> {
    return this.http.get<FilterData>(`${this.resourceService.getApiUrl()}/user/data?type=${type}`);
  }

  getFiltersData(type: string): Observable<string[]> {
    return this.http.get<FilterData>(`${this.resourceService.getApiUrl()}/user/data?type=${type}`)
      .pipe(tap(res => this.filterData = res), map((res) => res.data.map((x: FilterData) => x.name)));
  }

  saveFilter(filterLocation, body): Observable<FilterData> {
    return this.http.post<FilterData>(`${this.resourceService.getApiUrl()}/user/data?type=${filterLocation}`, body);
  }

  applySavedFilter(name, filterLocation): Observable<any> {
    return this.http.get<FilterData>(`${this.resourceService.getApiUrl()}/user/data?name=${name}&type=${filterLocation}`);
  }

  getSpecificFilter(name, filterLocation): Observable<any> {
    return this.http.get<FilterData>(`${this.resourceService.getApiUrl()}/user/data?name=${name}&type=${filterLocation}`);
  }

  deleteSavedFilter(id, name, filterLocation): Observable<FilterData> {
    return this.http.delete<FilterData>(`${this.resourceService.getApiUrl()}/user/data?user_id=${id}&name=${name}&type=${filterLocation}`);
  }

  // saveOnSort(event, filterApplied) {
  // if (this.appliedFilterService.getFilters(filterApplied)) {
  //     this.appliedFilterService.saveFilters(filterApplied, event, this.appliedFilterName,true);
  // } else {
  //     this.localStorageService.saveSort(this.router.url, this.cookieService.get("user"), "sortUser", event);
  // }
  // }
}
