import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as echarts from 'echarts';
import { ECharts } from 'echarts';
import { TabService } from 'services/tab.service';
import { AlarmsChartData, colors } from '../../../models';
import { ROUTE_CONFIGS } from 'src/app/models/constants/route-paths.constants';

@Component({
  selector: 'telco-active-operations-chart',
  templateUrl: './active-operations-chart.component.html',
  styleUrls: ['./active-operations-chart.component.scss']
})
export class ActiveOperationsChartComponent implements AfterViewInit, OnChanges {
  @Input() alarmCharData: AlarmsChartData;
  public alarmChart: any;
  public colors: typeof colors = colors;
  selectedLegend: object;
  stopRecalculteOnRefresh: boolean = false;
  myChart: ECharts;
  vnfTypesSum: number;
  @ViewChild('activeOpsChartSelector', { read: ElementRef, static: false }) activeOpsChartSelector: ElementRef;

  constructor(private router: Router, private tabService: TabService) {
    this.vnfTypesSum = 0;
  }

  ngAfterViewInit(): void {
    this.myChart = echarts.init(this.activeOpsChartSelector.nativeElement);
    this.myChart.on('legendselectchanged', function (params) {
      if (params['selected']) {
        this.selectedLegend = params['selected'];
        this.vnfTypesSum = 0;

        for (const legentItem in params['selected']) {
          if (params['selected'][legentItem]) {
            if (this.alarmCharData[legentItem] != undefined) {
              this.vnfTypesSum = this.vnfTypesSum + this.alarmCharData[legentItem]
            }
          }
        }
      }

      this.myChart.setOption({
        title: {
          text: this.vnfTypesSum,
          left: this.recenter(this.vnfTypesSum),
          top: "center",
          textStyle: {
            fontSize: 16,
            align: 'center',
            color: colors.TEXT,
            fontFamily: colors.FONT,
          }
        }
      });
    }.bind(this));

    this.resizeChart();
    this.myChart.on('click', function (params) {
      const title = 'Active Operations';
      const path = `${ROUTE_CONFIGS.NOTIFICATIONS_USER_OPERATIONS.route}/filterBy/${params.name}`;
      this.tabService.openTab(title, path);
    }.bind(this));
    
  }

  ngOnChanges(): void {
    if (this.alarmCharData != null) {
      // if(JSON.stringify(this.alarmCharData) == '{}'){
      //   this.alarmCharData['Pending'] = 112;
      //   this.alarmCharData['In progress'] = 150;
      // }
      this.initChart()
    }
  }

  getStatusUItoSystem(status) {
    let result = '';

    switch (status) {
      case 'Pending': {
        result = 'Pending'; break;
      }
      case 'In progress': {
        result = 'In progress'; break;
      }
      default: result = '';
    }

    return result;
  }

  calculateInit(edgeData) {
    let initValue = 0;

    if (edgeData['Pending'] != undefined) {
      initValue = initValue + edgeData['Pending'];
    }

    if (edgeData['In progress'] != undefined) {
      initValue = initValue + edgeData['In progress'];
    }

    return initValue;
  }

  recenter(value: number) {
    if (value > -1 && value < 10) {
      return '22.5%'
    }

    if (value >= 10 && value < 100) {
      return '21.5%'
    }

    if (value >= 100 && value < 1000) {
      return '20.5%'
    }

    if (value >= 1000 && value < 10000) {
      return '19.5%'
    }

    if (value >= 10000 && value < 100000) {
      return '18.5%'
    }

    if (value >= 100000 && value < 1000000) {
      return '17.5%'
    }

  }

  public initChart(): void {
    let newChartData: AlarmsChartData = new AlarmsChartData();

    if (this.selectedLegend) {
      for (const key in this.selectedLegend) {
        if (this.selectedLegend[key]) {
          const systemstatus = this.getStatusUItoSystem(key)
          newChartData[systemstatus] = this.alarmCharData[systemstatus]
        }
      }
    }
    else {
      newChartData = this.alarmCharData;
    }

    const initCount = this.calculateInit(newChartData)

    this.alarmChart = {
      title: {
        text: initCount,
        left: this.recenter(initCount),
        top: "center",
        textStyle: {
          fontSize: 16,
          align: 'center',
          color: colors.TEXT,
          fontFamily: colors.FONT,

        }
      },
      tooltip: {
        trigger: 'item',
        textStyle: {
          color: colors.TEXT,
          fontFamily: colors.FONT,
        }
      },
      legend: {
        top: 'center',
        left: 250,
        data: [ { name: 'Pending', itemStyle: { color: colors.YELLOW } },
                  //  { name: 'Major', itemStyle: { color: colors.ORANGE } },
                { name: 'In progress', itemStyle: { color: colors.GREEN } }
              ],
        textStyle: {
          color: colors.TEXT,
          fontFamily: colors.FONT,
        }
      },
      series: [{
        type: 'pie',
        radius: ['45%', '90%'],
        center: ['25%', '50%'],
        label: {
          show: true,
          position: 'inner',
          color: colors.TEXT,
          fontFamily: colors.FONT,
          formatter: (params) => {
            return params.value;
          }
        },
        labelLine: {
          normal: {
            show: false,
            //   position: 'inner',
            //   formatter: (params) => {
            //       return 10;
            //   }

          }
        },
        hoverAnimation: false,
        avoidLabelOverlap: false,

        itemStyle: {
          borderRadius: 5,
          borderColor: '#fff',
          // borderWidth: 1
        },

        data: this.convertNameValueArray(this.alarmCharData)

      }]
    };

    if (this.selectedLegend != null) {
      this.alarmChart['legend']['selected'] = this.selectedLegend;
      this.alarmChart['legend']['selectedMode'] = true;
      this.stopRecalculteOnRefresh = true;
    }
  }

  convertNameValueArray(chartData: AlarmsChartData) {
    const newArrayData = []

    if (chartData['Pending']) {
      newArrayData.push({ name: 'Pending', value: chartData['Pending'], itemStyle: { color: colors.YELLOW } });
    }

    if (chartData['In progress']) {
      newArrayData.push({ name: 'In progress', value: chartData['In progress'], itemStyle: { color: colors.GREEN } });
    }

    this.resizeChart();
    return newArrayData
  }

  onChartClick() {
  }

  resizeChart() {
    if (this.myChart) {
      this.myChart.resize({ width: 382, height: 209 });
    }
  }
}
