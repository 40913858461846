import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as echarts from 'echarts';
import { ECharts } from 'echarts';
import { TabService } from 'services/tab.service';
import { AlarmsChartData, colors } from '../../../models';
import { DASHBOARD_ROUTE_CONFIGS } from '../models/dashboard-route-path.constants';

@Component({
  selector: 'telco-controller-event-chart',
  templateUrl: './controller-event-chart.component.html',
  styleUrls: ['./controller-event-chart.component.scss']
})
export class ControllerEventChartComponent implements OnChanges, AfterViewInit {
  @Input() activeEventCharData: AlarmsChartData;
  @Input() title: string;
  dependOnTitle = {
    'Controller Events': 'Notifications/ControllerEvents',
    'Kubernetes Service Events': 'Notifications/ServiceEvents',
    'Openstack Service Events': 'Notifications/OpenstackServiceEvents'
  }
  public alarmChart: any;
  public colors: typeof colors = colors;
  selectedLegend: object;
  stopRecalculteOnRefresh: boolean = false;
  myChart: ECharts;
  vnfTypesSum: number;
  @ViewChild('contrlChartSelector', { read: ElementRef, static: false }) contrlChartSelector: ElementRef;

  constructor(private router: Router, private tabService: TabService) {
    this.vnfTypesSum = 0;
  }

  ngAfterViewInit(): void {
    this.myChart = echarts.init(this.contrlChartSelector.nativeElement);
    this.myChart.on('legendselectchanged', function (params) {
      if (params['selected']) {
        this.selectedLegend = params['selected'];
        this.vnfTypesSum = 0;

        for (const legentItem in params['selected']) {
          if (params['selected'][legentItem]) {
            if (this.activeEventCharData[legentItem] != undefined) {
              this.vnfTypesSum = this.vnfTypesSum + this.activeEventCharData[legentItem]
            }
          }
        }
      }

      this.myChart.setOption({
        title: {
          text: this.vnfTypesSum,
          left: this.recenter(this.vnfTypesSum),
          top: "center",
          textStyle: {
            fontSize: 16,
            align: 'center',
            color: colors.TEXT,
            fontFamily: colors.FONT,
          }
        }
      });
    }.bind(this));

    this.resizeChart();
    this.myChart.on('click', function (params) {

      let title = '';
      let path = '';
      if (this.title == 'Controller Events') {
        title = DASHBOARD_ROUTE_CONFIGS.CONTROLLER_EVENTS.title;
        path = DASHBOARD_ROUTE_CONFIGS.CONTROLLER_EVENTS.route.replace(':severity', params.name.toString()).replace(':controllerHostname', 'hostName').replace(':controllerId', 'true');
      } else if (this.title == 'Kubernetes Service Events') {
        title = DASHBOARD_ROUTE_CONFIGS.K8S_SERVICE_EVENTS.title;
        path = DASHBOARD_ROUTE_CONFIGS.K8S_SERVICE_EVENTS.route.replace(':severity', params.name.toString()).replace(':controllerHostname', 'hostName').replace(':controllerId', 'true');
      } else if (this.title == 'Openstack Service Events') {
        title = DASHBOARD_ROUTE_CONFIGS.OS_SERVICE_EVENTS.title;
        path = DASHBOARD_ROUTE_CONFIGS.OS_SERVICE_EVENTS.route.replace(':severity', params.name.toString()).replace(':controllerHostname', 'hostName').replace(':controllerId', 'true');
      } else if (this.title == 'Helm Service Events') {
        title = 'Helm Service Events';
        path = 'Notifications/HelmChartEvents'.replace(':severity', params.name.toString()).replace(':controllerHostname', 'hostName').replace(':controllerId', 'true');
      }
      this.tabService.openTab(title, path);
    }.bind(this));
  }

  ngOnChanges(): void {
    if (this.activeEventCharData != null) {
      // if(JSON.stringify(this.activeEventCharData) == '{}'){
      //   this.activeEventCharData['Normal'] = 12;
      //   this.activeEventCharData['Info'] = 15;
      //   this.activeEventCharData['Critical'] = 120;
      //   this.activeEventCharData['Warning'] = 10;
      // }

      this.initChart()
    }
  }

  getStatusUItoSystem(status) {
    let result = '';

    switch (status) {
      case 'Warning': {
        result = 'Warning'; break;
      }
      case 'Critical': {
        result = 'Critical'; break;
      }
      case 'Normal': {
        result = 'Normal'; break;
      }
      case 'Info': {
        result = 'Info'; break;
      }
      default: result = '';
    }

    return result;
  }

  calculateInit(edgeData) {
    let initValue = 0;

    if (edgeData['Warning'] != undefined) {
      initValue = initValue + edgeData['Warning'];
    }

    if (edgeData['Critical'] != undefined) {
      initValue = initValue + edgeData['Critical'];
    }

    if (edgeData['Normal'] != undefined) {
      initValue = initValue + edgeData['Normal'];
    }

    if (edgeData['Info'] != undefined) {
      initValue = initValue + edgeData['Info'];
    }

    return initValue;
  }

  recenter(value: number) {
    if (value > -1 && value < 10) {
      return '22.5%'
    }

    if (value >= 10 && value < 100) {
      return '21.5%'
    }

    if (value >= 100 && value < 1000) {
      return '20.5%'
    }

    if (value >= 1000 && value < 10000) {
      return '19.5%'
    }

    if (value >= 10000 && value < 100000) {
      return '18.5%'
    }

    if (value >= 100000 && value < 1000000) {
      return '17.5%'
    }

  }

  public initChart(): void {
    let newChartData: AlarmsChartData = new AlarmsChartData();

    if (this.selectedLegend) {
      for (const key in this.selectedLegend) {
        if (this.selectedLegend[key]) {
          const systemstatus = this.getStatusUItoSystem(key)
          newChartData[systemstatus] = this.activeEventCharData[systemstatus]
        }
      }
    } else {
      newChartData = this.activeEventCharData;
    }

    const initCount = this.calculateInit(newChartData)

    this.alarmChart = {
      title: {
        text: initCount,
        left: this.recenter(initCount),
        top: "center",
        textStyle: {
          fontSize: 16,
          align: 'center',
          color: colors.TEXT,
          fontFamily: colors.FONT,

        }
      },
      tooltip: {
        trigger: 'item',
        textStyle: {
          color: colors.TEXT,
          fontFamily: colors.FONT,
        }
      },
      legend: {
        top: 'center',
        left: 250,
        data: [{ name: 'Critical', itemStyle: { color: colors.RED } },
        //  { name: 'Major', itemStyle: { color: colors.ORANGE } },
        //  { name: 'Minor', itemStyle: { color: colors.YELLOW } },
        { name: 'Warning', itemStyle: { color: colors.YELLOW } },
        { name: 'Info', itemStyle: { color: colors.BLUE } },
        { name: 'Normal', itemStyle: { color: colors.GREEN } }],
        textStyle: {
          color: colors.TEXT,
          fontFamily: colors.FONT,
        }
      },
      series: [{
        type: 'pie',
        radius: ['45%', '90%'],
        center: ['25%', '50%'],
        label: {
          show: true,
          position: 'inner',
          color: colors.TEXT,
          fontFamily: colors.FONT,
          formatter: (params) => {
            return params.value;
          }
        },
        labelLine: {
          normal: {
            show: false,
            //   position: 'inner',
            //   formatter: (params) => {
            //       return 10;
            //   }

          }
        },
        hoverAnimation: false,
        avoidLabelOverlap: false,

        itemStyle: {
          borderRadius: 5,
          borderColor: '#fff',
          // borderWidth: 1
        },

        data: this.convertNameValueArray(this.activeEventCharData)
      }]
    };

    if (this.selectedLegend != null) {
      this.alarmChart['legend']['selected'] = this.selectedLegend;
      this.alarmChart['legend']['selectedMode'] = true;
      this.stopRecalculteOnRefresh = true;
    }
  }

  convertNameValueArray(chartData: AlarmsChartData) {
    const newArrayData = []

    if (chartData.Warning) {
      newArrayData.push({ name: 'Warning', value: chartData.Warning, itemStyle: { color: colors.YELLOW } });
    }

    if (chartData.Critical) {
      newArrayData.push({ name: 'Critical', value: chartData.Critical, itemStyle: { color: colors.RED } });
    }

    if (chartData.Info) {
      newArrayData.push({ name: 'Info', value: chartData.Info, itemStyle: { color: colors.BLUE } });
    }

    if (chartData.Normal) {
      newArrayData.push({ name: 'Normal', value: chartData.Normal, itemStyle: { color: colors.GREEN } });
    }

    this.resizeChart();
    return newArrayData
  }

  onChartClick() {
  }

  resizeChart() {
    if (this.myChart) {
      this.myChart.resize({ width: 382, height: 209 });
    }
  }
}
