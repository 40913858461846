export class Operator {
    id: number;
    name: string;
    contact:string;
    project:string;
    project_id:number;

    constructor(id?, name?, contact?, project_id?, project?, public max_shared?) {
        this.id = id;
        this.name = name;
        this.contact=contact;
        this.project=project;
        this.project_id = project_id;
    }
}
