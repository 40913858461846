import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as echarts from 'echarts';
import { ECharts } from 'echarts';
import { TabService } from 'services/tab.service';
import { colors } from 'src/app/models';
import { AlertsChartData } from 'src/app/models/interfaces/AlertsChartData';
import { DASHBOARD_ROUTE_CONFIGS } from '../models/dashboard-route-path.constants';

@Component({
  selector: 'telco-alerts-chart',
  templateUrl: './alerts-chart.component.html',
  styleUrls: ['./alerts-chart.component.scss']
})
export class AlertsChartComponent implements AfterViewInit, OnChanges {
  @ViewChild('alertsChartSelector', { read: ElementRef, static: false }) alertsChartSelector: ElementRef;
  @Input() alertsCharData: AlertsChartData;

  alertsChart: any;
  liveView: boolean;
  colors: typeof colors = colors;
  selectedLegend: object;
  myChart: ECharts;
  alertsCount: number;

  constructor(private router: Router, private tabService: TabService) {
    this.alertsCount = 0;
  }

  ngAfterViewInit(): void {
    this.myChart = echarts.init(this.alertsChartSelector.nativeElement);

    this.myChart.on('legendselectchanged', function (params) {
      if (params['selected']) {
        this.selectedLegend = params['selected'];
        this.alertsCount = this.selectedLegend['Active Alerts'] ? this.alertsCharData.count : 0;
      }

      this.myChart.setOption({
        title: {
          text: this.alertsCount,
          left: this.recenter(this.alertsCount),
          top: "center",
          textStyle: {
            fontSize: 16,
            align: 'center',
            color: colors.TEXT,
            fontFamily: colors.FONT,
          }
        }
      });
    }.bind(this));

    this.resizeChart();
    this.myChart.on('click', function () {
      const title = DASHBOARD_ROUTE_CONFIGS.ALERTS.title;
      const path = DASHBOARD_ROUTE_CONFIGS.ALERTS.route;
      this.tabService.openTab(title, path);
    }.bind(this));
  }

  ngOnChanges(): void {
    if (this.alertsCharData != null) {
      if (this.myChart) {
        this.initChart();
      }
    }
  }

  initChart() {
    let newChartData: AlertsChartData = new AlertsChartData();

    if (this.selectedLegend) {
      const systemStatus = 'Active Alerts'
      newChartData[systemStatus] = this.alertsCharData[systemStatus]
    } else {
      newChartData = this.alertsCharData;
    }

    this.alertsCount = this.calculateInit(newChartData)

    this.alertsChart = {
      title: {
        text: this.alertsCount,
        left: this.recenter(this.alertsCount),
        top: "center",
        textStyle: {
          fontSize: 16,
          align: 'center',
          color: colors.TEXT,
          fontFamily: colors.FONT,
        }
      },
      tooltip: {
        trigger: 'item',
        textStyle: {
          color: colors.TEXT,
          fontFamily: colors.FONT,
        }
      },
      //TODO: In this case legend may be not need but asc
      legend: {
        top: 'center',
        left: 250,
        data: [{ name: 'Active Alerts', itemStyle: { color: colors.RED } }],
        textStyle: {
          color: colors.TEXT,
          fontFamily: colors.FONT,
        }
      },
      series: [{
        type: 'pie',
        radius: ['45%', '90%'],
        center: ['25%', '50%'],
        label: {
          show: true,
          position: 'inner',
          color: colors.TEXT,
          fontFamily: colors.FONT,
          formatter: (params) => {
            return params.value;
          }
        },
        labelLine: {
          normal: {
            show: false,
          }
        },
        hoverAnimation: false,
        avoidLabelOverlap: false,

        itemStyle: {
          borderRadius: 5,
          borderColor: '#fff',
        },
        data: this.alertsCharData.count > 0 ? [{ name: 'Active Alerts', value: this.alertsCharData.count, itemStyle: { color: colors.RED } }] : []
      }]
    };

    if (this.selectedLegend != null) {
      this.alertsChart['legend']['selected'] = this.selectedLegend;
      this.alertsChart['legend']['selectedMode'] = true;
    }
  }

  calculateInit(edgeData) {
    let initValue = 0;
    if (edgeData?.count) {
      initValue = initValue + edgeData.count;
    }

    return initValue;
  }

  recenter(value: number) {
    this.resizeChart();

    if (value > -1 && value < 10) {
      return '22.5%'
    }

    if (value >= 10 && value < 100) {
      return '21.5%'
    }

    if (value >= 100 && value < 1000) {
      return '20.5%'
    }

    if (value >= 1000 && value < 10000) {
      return '19.5%'
    }

    if (value >= 10000 && value < 100000) {
      return '18.5%'
    }

    if (value >= 100000 && value < 1000000) {
      return '17.5%'
    }
  }

  resizeChart() {
    if (this.myChart) {
      this.myChart.resize({ width: 382, height: 209 });
    }
  }
}