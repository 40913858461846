import { Action, on } from '@ngrx/store';
import { TabState } from 'src/app/app.state';
import * as TabActions from '../actions/tab.actions';
import { createRehydrateReducer } from './rehydrate.reducer';

export const initialState: TabState[] = [];

const key = 'tabsState';

const tabReducer = createRehydrateReducer(
  key,
  initialState,
  on(TabActions.openTab, (state, { id, title, path, component, currentActiveId }): TabState[] => [
    ...state.map(tab => ({ ...tab, isActive: false })),
    { id, title, path, component, isActive: true, data: null, currentActiveId }
  ]),
  on(TabActions.closeTab, (state, { id }): TabState[] => state.filter(tab => tab.id !== id)),
  on(TabActions.switchTab, (state, { id }): TabState[] => state.map(tab => ({
    ...tab,
    isActive: tab.id === id
  }))),
  on(TabActions.updateTabData, (state, { id, data }): TabState[] => state.map(tab =>
    tab.id === id ? { ...tab, data } : tab
  )),
  on(TabActions.logout, (): TabState[] => initialState),
  on(TabActions.setTabs, (state, { tabs }) => tabs)
);

export function reducer(state: TabState[] = initialState, action: Action): TabState[] {
  return tabReducer(state, action);
}