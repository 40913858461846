interface Icon {
    name: string;
    url: string;
    namespace?: string;
}

export const Icons: Icon[] = [
    {
        name: 'openstack',
        url: "/assets/svg/openstack-icon.svg"
    },
    {
        name: 'kubernetes',
        url: "/assets/svg/kubernetes-svgrepo-com.svg"
    },
    {
        name: 'vm-k8s-color',
        url: "/assets/svg/vm-k8s-color.svg"
    },
    {
        namespace: "labeled",
        name: 'pod',
        url: "/assets/svg/k8s_resources/labeled/pod.svg"
    },
    {
        namespace: "labeled",
        name: "pv",
        url: "/assets/svg/k8s_resources/labeled/pv.svg"
    },
    {
        namespace: "labeled",
        name: "pvc",
        url: "/assets/svg/k8s_resources/labeled/pvc.svg"
    },
    {
        namespace: "labeled",
        name: "ing",
        url: "/assets/svg/k8s_resources/labeled/ing.svg"
    },
    {
        namespace: "labeled",
        name: "secret",
        url: "/assets/svg/k8s_resources/labeled/secret.svg"
    },
    {
        namespace: "labeled",
        name: "cm",
        url: "/assets/svg/k8s_resources/labeled/cm.svg"
    },
    {
        namespace: "labeled",
        name: "deploy",
        url: "/assets/svg/k8s_resources/labeled/deploy.svg"
    },
    {
        namespace: "labeled",
        name: "service",
        url: "/assets/svg/k8s_resources/labeled/svc.svg"
    },
    {
        namespace: "labeled",
        name: "vm",
        url: "/assets/svg/vm.svg"
    },
    {
        namespace: "labeled",
        name: "replicaset",
        url: "/assets/svg/k8s_resources/labeled/rs.svg"
    },
    {
        namespace: "labeled",
        name: "statefulset",
        url: "/assets/svg/k8s_resources/labeled/sts.svg"
    },
    {
        namespace: "labeled",
        name: "ds",
        url: "/assets/svg/k8s_resources/labeled/ds.svg"
    },
    {
        namespace: "labeled",
        name: "job",
        url: "/assets/svg/k8s_resources/labeled/job.svg"
    },
    {
        namespace: "labeled",
        name: "cronjob",
        url: "/assets/svg/k8s_resources/labeled/cronjob.svg"
    },
    {
        namespace: "labeled",
        name: "hpa",
        url: "/assets/svg/k8s_resources/labeled/hpa.svg"
    },
    {
        namespace: "labeled",
        name: "role",
        url: "/assets/svg/k8s_resources/labeled/role.svg"
    },
    {
        namespace: "labeled",
        name: "crole",
        url: "/assets/svg/k8s_resources/labeled/c-role.svg"
    },
    {
        namespace: "labeled",
        name: "crolebinding",
        url: "/assets/svg/k8s_resources/labeled/crb.svg"
    },
    {
        namespace: "labeled",
        name: "rolebinding",
        url: "/assets/svg/k8s_resources/labeled/rb.svg"
    },
    {
        namespace: "labeled",
        name: "netpol",
        url: "/assets/svg/k8s_resources/labeled/netpol.svg"
    },
    {
        namespace: "labeled",
        name: "sa",
        url: "/assets/svg/k8s_resources/labeled/sa.svg"
    },
    {
      namespace: 'labeled',
      name: 'dhcp',
      url: '/assets/svg/k8s_resources/labeled/dhcp.svg'
    },
    {
      namespace: 'labeled',
      name: 'crd',
      url: '/assets/svg/k8s_resources/labeled/crd.svg'
    },
    {
      namespace: 'labeled',
      name: 'vol',
      url: '/assets/svg/k8s_resources/labeled/vol.svg'
    },
  {
    namespace: 'labeled',
    name: 'sc',
    url: '/assets/svg/k8s_resources/labeled/sc.svg'
  },
  {
    namespace: 'labeled',
    name: 'sc',
    url: '/assets/svg/k8s_resources/labeled/sc.svg'
  }
]
