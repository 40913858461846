import { BasicEdgeInterface } from "./BasicEdgeInterface";

export class Ipsec implements BasicEdgeInterface {

  constructor() {
    this.enable = false;
    this.name = 'vti0';
    this.type = 'ipsecvpn';
    this.management = true;
    this['protocol-mode'] = 'tunnel';
    this['ike-version'] = '2';
    this['config-mode'] = 'pull';
    this['ike-phase-1'] = new IkePhase1();
    this['ike-phase-2'] = new IkePhase2();
    this["install-virtual-ip"] = true;
    this["virtual-ip"] = "0.0.0.0";
  }

  enable: boolean;
  name: string;
  type: string;
  management: boolean;
  'protocol-mode': string;
  'remote-gw': string;
  'remove-id': string;
  'local-id': string;
  'config-mode': string;
  'ike-version': string;
  'ike-mode': string;
  'ike-phase-1': IkePhase1;
  'ike-phase-2': IkePhase2;
  "install-virtual-ip": boolean;
  "virtual-ip": string;
}

export class IkePhase {
  local: AuthRound;
  remote: AuthRound;
  constructor() {
    this.proposals = [];
    this['dh-groups'] = [];
    this.proposals.push(new Proposals(1))
  }
  proposals: Proposals[];
  'dh-groups': DiffieHellmanGroup[];
}

export class IkePhase1 extends IkePhase {
  constructor() {
    super();
    this.local = new AuthRound();
    this.remote = new AuthRound();
  }
  local: AuthRound;
  remote: AuthRound;
}

export class IkePhase2 extends IkePhase {
  constructor() {
    super();
  }
  'local-ts': string;
  'remote-ts': string;
}

export class AuthRound {
  rounds: AuthenticationMethod[] = [];
}

export class Proposals {
  constructor(id: number) {
    this.id = id;
  }
  id: number;
  'authentication-algorithm': string;
  'encryption-algorithm': string;
}

export class DiffieHellmanGroup {
  constructor(number: number) {
    this.group = number;
  }
  group: number;
}

export class AuthenticationMethod {
  constructor(id: number, auth: string) {
    this.id = id;
    this["authentication-method"] = auth;
  }
  id: number;
  'authentication-method': string;
}

export class Pubkeys extends AuthenticationMethod {
  constructor(id: number, auth: string = 'pubkeys') {
    super(id, auth);
  }
  cert: string;
  'ca-cert': string;
  key: string;
  'key-pass': string;
}

export class PreSharedKeys extends AuthenticationMethod {
  constructor(id: number) {
    super(id, 'pre-shared-keys');
  }
  secret: string;
}

export class Eap extends Pubkeys {
  constructor(id: number) {
    super(id, 'eap');
  }
  username: string;
  password: string;
}

export class Xauth extends AuthenticationMethod {
  constructor(id: number) {
    super(id, 'xauth');
  }
  username: string;
  password: string;
}
