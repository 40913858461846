import { createAction, props } from '@ngrx/store';
import { TabState } from 'src/app/app.state';

enum ActionTypes {
  tabOpen = '[Tab] Open',
  tabClose = '[Tab] Close',
  tabSwitch = '[Tab] Switch',
  tabUpdate = '[Tab] Update Data',
  authLogout = '[Auth] Logout',
  setTabs = '[Tabs] Set'
}

export const openTab = createAction(ActionTypes.tabOpen, props<{ id: string; title: string; path: string; component: any, currentActiveId: string }>());
export const closeTab = createAction(ActionTypes.tabClose, props<{ id: string }>());
export const switchTab = createAction(ActionTypes.tabSwitch, props<{ id: string }>());
export const updateTabData = createAction(ActionTypes.tabUpdate, props<{ id: string; data: any }>());
export const logout = createAction(ActionTypes.authLogout);
export const setTabs = createAction(ActionTypes.setTabs, props<{ tabs: TabState[] }>());