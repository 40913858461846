import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as echarts from 'echarts';
import { ECharts } from 'echarts';
import { TabService } from 'services/tab.service';
import { EdgeStatuses, colors } from '../../../models';
import { DASHBOARD_ROUTE_CONFIGS } from '../models/dashboard-route-path.constants';


export interface VnfElement {
  name: string;
  value: number;
}

@Component({
  selector: 'app-ucpe-charts',
  templateUrl: './ucpe-charts.component.html',
  styleUrls: ['./ucpe-charts.component.scss']
})
export class UcpeChartsComponent implements OnChanges, AfterViewInit {

  @ViewChild('edgeChartSelector', { read: ElementRef, static: false }) edgeChartSelector: ElementRef;
  @Input() ucpeCharData: EdgeStatuses;
  @Input() typeChart: string = '';

  colors: typeof colors = colors;
  isExpanded: boolean = false;
  selectedLegend: object; // Record<string, any>;
  stopRecalculteOnRefresh = false;
  chartName = '';
  redirectUrl = '';
  chartHeader: string;
  myChart: ECharts;
  vnfTypesSum = 0;
  convertedNameValueArray: object; // Record<string, any>;

  ucpeChart: any;

  constructor(private router: Router, private tabService: TabService) { }

  ngOnChanges(): void {
    if (this.ucpeCharData != null) {
      this.initHeaderChart()
      this.initChart()

      if (this.selectedLegend != null) {
        //TODO: researh how to set legend selected
        // this.myChart.setOption({legend:{selected:this.selectedLegend,selectedMode: true}})
      }
    }
  }

  ngAfterViewInit(): void {
    // var chartDom = document.getElementById('vnfTypeChartSelector')!;
    this.myChart = echarts.init(this.edgeChartSelector.nativeElement);
    this.myChart.on('legendselectchanged', function (params) {

      if (params['selected']) {
        this.selectedLegend = params['selected'];
        this.vnfTypesSum = 0;

        for (const legentItem in params['selected']) {
          if (params['selected'][legentItem]) {

            if (this.ucpeCharData[legentItem.toLowerCase()] != undefined) {
              this.vnfTypesSum = this.vnfTypesSum + this.ucpeCharData[legentItem.toLowerCase()];
            }
          }
        }
      }

      this.myChart.setOption({
        title: {
          text: this.vnfTypesSum,
          left: this.recenter(this.vnfTypesSum),
          top: "center",
          textStyle: {
            fontSize: 16,
            align: 'center'
          }
        }
      });
    }.bind(this));

    this.resizeChart();
    this.myChart.on('click', function (params) {
      let title: string;
      let path: string;

      if (this.typeChart === 'ucpe') {
        title = DASHBOARD_ROUTE_CONFIGS.OPER_STATUS_EDGES.title;
        path = DASHBOARD_ROUTE_CONFIGS.OPER_STATUS_EDGES.route.replace(':name', params.name.toString()).replace(':chartName', this.chartName.toString());
      } else if (this.typeChart === 'controller') {
        title = DASHBOARD_ROUTE_CONFIGS.OPER_STATUS_CONTROLLERS.title;
        path = DASHBOARD_ROUTE_CONFIGS.OPER_STATUS_CONTROLLERS.route.replace(':name', params.name.toString()).replace(':chartName', this.chartName.toString());
      }
      this.tabService.openTab(title, path);
    }.bind(this));
  }

  initHeaderChart(): void {
    const chartMapping = {
      'ucpe': {
        chartHeader: 'Edge Oper Statuses',
        redirectUrl: 'Edge Management/EdgeDevices/ED',
        chartName: 'operStatuses'
      },
      'controller': {
        chartHeader: 'Controller Oper Statuses',
        redirectUrl: 'Controller Management/Controllers/Controller/',
        chartName: 'controllerStatuses'
      }
    };

    if (this.typeChart && chartMapping[this.typeChart]) {
      this.chartHeader = chartMapping[this.typeChart].chartHeader;
      this.chartName = chartMapping[this.typeChart].chartName;
      this.redirectUrl = chartMapping[this.typeChart].redirectUrl;
    }
  }

  getStatusUItoSystem(status: string): string {
    const statusMapping = {
      'Configuring': 'busy',
      'Degraded': 'degraded',
      'Not contacted': 'not contacted',
      'Rebooting': 'rebooting',
      'Running': 'running',
      'Starting': 'starting',
      'Unlicensed': 'unlicensed',
      'Unreachable': 'unreachable',
      'Upgrading': 'upgrading',
      'Stopping': 'stopping'
    };

    return statusMapping[status] || '';
  }

  calculateInit(edgeData: any): number {
    const keysOfInterest = [
      'busy', 'running', 'unreachable', 'starting', 'rebooting',
      'degraded', 'unlicensed', 'upgrading', 'not contacted', 'stopping'
    ];

    return keysOfInterest.reduce((acc, key) => {
      return acc + (edgeData[key] || 0);
    }, 0);
  }

  recenter(value: number) {
    const thresholds = [
      { max: 10, percent: '22.5%' },
      { max: 100, percent: '21.5%' },
      { max: 1000, percent: '20.5%' },
      { max: 10000, percent: '19.5%' },
      { max: 100000, percent: '18.5%' },
      { max: 1000000, percent: '17.5%' }
    ];

    for (const threshold of thresholds) {
      if (value < threshold.max) {
        return threshold.percent;
      }
    }
    return '17.5%'; // Default value return
  }

  convertNameValueArray(chartData: EdgeStatuses) {
    const statusColorMap = {
      busy: { name: 'Configuring', color: colors.LIGHT_BLUE },
      running: { name: 'Running', color: colors.GREEN },
      unreachable: { name: 'Unreachable', color: colors.RED },
      starting: { name: 'Starting', color: colors.YELLOW },
      rebooting: { name: 'Rebooting', color: colors.ORANGE },
      unlicensed: { name: 'Unlicensed', color: colors.PINK },
      upgrading: { name: 'Upgrading', color: colors.BLUE },
      degraded: { name: 'Degraded', color: colors.VIOLET },
      'not contacted': { name: 'Not contacted', color: colors.GRAY },
      stopping: { name: 'Stopping', color: colors.OLIVE }
    };

    const newArrayData = Object.keys(statusColorMap)
      .filter(status => chartData[status])
      .map(status => ({
        name: statusColorMap[status].name,
        value: chartData[status],
        itemStyle: { color: statusColorMap[status].color }
      }));

    this.resizeChart();
    return newArrayData;
  }

  onChartClick() {
    //console.log(event.name)
  }

  public initChart(): void {
    let newChartData: EdgeStatuses = new EdgeStatuses();

    if (this.selectedLegend) {
      for (const key in this.selectedLegend) {
        if (this.selectedLegend[key]) {
          const systemstatus = this.getStatusUItoSystem(key)
          newChartData[systemstatus] = this.ucpeCharData[systemstatus]
        }
      }
    }
    else {
      newChartData = this.ucpeCharData;
    }

    const initCount = this.calculateInit(newChartData)
    this.ucpeChart = {
      title: {
        text: initCount,
        top: "center",
        left: this.recenter(initCount),
        textStyle: {
          fontSize: 16,
          align: 'center',
          color: colors.TEXT,
          fontFamily: colors.FONT,
        }
      },
      tooltip: {
        trigger: 'item',
        textStyle: {
          color: colors.TEXT,
          fontFamily: colors.FONT,
        }
      },
      legend: {
        top: 'center',
        left: 250,
        data: [{ name: 'Unreachable', itemStyle: { color: colors.RED } }, { name: 'Running', itemStyle: { color: colors.GREEN } }, { name: 'Starting', itemStyle: { color: colors.YELLOW } }, { name: 'Rebooting', itemStyle: { color: colors.ORANGE } }, { name: 'Degraded', itemStyle: { color: colors.VIOLET } }, { name: 'Configuring', itemStyle: { color: colors.LIGHT_BLUE } }, { name: 'Unlicensed', itemStyle: { color: colors.PINK } }, { name: 'Upgrading', itemStyle: { color: colors.BLUE } }, { name: 'Not contacted', itemStyle: { color: colors.GRAY } }, { name: 'Stopping', itemStyle: { color: colors.OLIVE } }],
        textStyle: {
          color: colors.TEXT,
          fontFamily: colors.FONT,
        }
      },
      series: [{
        type: 'pie',
        radius: ['45%', '90%'],
        center: ['25%', '50%'],
        label: {
          show: true,
          color: colors.TEXT,
          position: 'inner',
          fontFamily: colors.FONT,
          formatter: (params) => {
            return params.value;
          }
        },
        labelLine: {
          normal: {
            show: false,
            //   position: 'inner',
            //   formatter: (params) => {
            //       return 10;
            //   }

          }
        },
        hoverAnimation: false,
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 5,
          borderColor: '#fff',
          // borderWidth: 1
        },

        data: this.convertNameValueArray(this.ucpeCharData)
      }]
    };

    if (this.selectedLegend != null) {
      this.ucpeChart['legend']['selected'] = this.selectedLegend;
      this.ucpeChart['legend']['selectedMode'] = true;
      this.stopRecalculteOnRefresh = true

    }
  }

  resizeChart() {
    if (this.myChart) {
      this.myChart.resize({ width: 382, height: 209 });
    }
  }
}
