import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from 'services/theme.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Icons } from './models/constants/icons';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  constructor(public router: Router, private themeService: ThemeService, private breadcrumbService: BreadcrumbService,
    private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.loadIconsSet();
  }

  loadIconsSet() {
    Icons.forEach(el => {
      if (el.namespace) {
        this.matIconRegistry.addSvgIconInNamespace(el.namespace, el.name, this.domSanitizer.bypassSecurityTrustResourceUrl(el.url));
      } else {
        this.matIconRegistry.addSvgIcon(el.name, this.domSanitizer.bypassSecurityTrustResourceUrl(el.url));
      }
    })
  }

  title = 'Manager 2.0';
}
