import { RouterModule, Routes } from "@angular/router";
import { EntryComponent } from "./components/entry/entry.component";
import { AuthGuard } from "./components/guards/auth.guard";
import { DeviceGuard } from "./components/guards/device.guard";
import { DashboardComponent } from "./components/pages/dashboard/dashboard.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { MobileEntryComponent } from "./mobile/components/mobile-entry/entry-mobile.component";
import { DashboardMobileComponent } from "./mobile/components/pages/dashboard/dashboard-mobile.component";
import { ROUTE_CONFIGS } from "./models/constants/route-paths.constants";

const routes: Routes = [
	{ path: "", redirectTo: "Login", pathMatch: "full" },
	{ path: "Login", component: LoginComponent },
	{
		path: "home",
		component: EntryComponent,
		canActivate: [AuthGuard],
		data: { breadcrumb: { skip: true } },
		children: [
			{
				path: `:id${ROUTE_CONFIGS.DASHBOARD.route}`,
				component: DashboardComponent,
				canActivate: [DeviceGuard],
				data: { breadcrumb: ROUTE_CONFIGS.DASHBOARD.title }
			},
			{
				path: `:id${ROUTE_CONFIGS.EDGE_MANAGEMENT.route}`,
				canActivate: [AuthGuard],
				canActivateChild: [AuthGuard],
				data: { breadcrumb: ROUTE_CONFIGS.EDGE_MANAGEMENT.title },
				children: [
					{
						path: ROUTE_CONFIGS.EDGE_DEVICES.route.split('/')[2],
						data: { breadcrumb: ROUTE_CONFIGS.EDGE_DEVICES.title },
						loadChildren: () => import("./modules/EdgeManagement/Devices/devices.module").then((m) => m.DevicesModule)
					}, {
						path: ROUTE_CONFIGS.OPERATORS.route.split('/')[2],
						data: { breadcrumb: ROUTE_CONFIGS.OPERATORS.title },
						loadChildren: () => import("./modules/EdgeManagement/operators/operators.module").then((m) => m.OperatorsModule)
					}, {
						path: ROUTE_CONFIGS.CUSTOMERS.route.split('/')[2],
						data: { breadcrumb: ROUTE_CONFIGS.CUSTOMERS.title },
						loadChildren: () => import("./modules/EdgeManagement/customers/customers.module").then((m) => m.CustomersModule)
					}, {
						path: ROUTE_CONFIGS.SERVICES.route.split('/')[2],
						data: { breadcrumb: ROUTE_CONFIGS.SERVICES.title },
						loadChildren: () => import("./modules/EdgeManagement/services/services.module").then((m) => m.ServicesModule)
					}, {
						path: ROUTE_CONFIGS.SITES.route.split('/')[2],
						data: { breadcrumb: ROUTE_CONFIGS.SITES.title },
						loadChildren: () => import("./modules/EdgeManagement/sites/sites.module").then((m) => m.SitesModule)
					}, {
						path: ROUTE_CONFIGS.HARDWARE_TYPES.route.split('/')[2],
						data: { breadcrumb: ROUTE_CONFIGS.HARDWARE_TYPES.title },
						loadChildren: () => import("./modules/EdgeManagement/hwtypes/hwtypes.module").then((m) => m.HwtypesModule)
					}, {
						path: ROUTE_CONFIGS.EDGE_TEMPLATES.route.split('/')[2],
						data: { breadcrumb: ROUTE_CONFIGS.EDGE_TEMPLATES.title },
						loadChildren: () => import("./modules/EdgeManagement/edge-templates/edge-templates.module").then((m) => m.EdgeTemplatesModule)
					},
				]
			},
			{

				path: `:id${ROUTE_CONFIGS.CONTROLLER_MANAGEMENT.route}`,
				canActivate: [AuthGuard],
				canActivateChild: [AuthGuard],
				data: { breadcrumb: ROUTE_CONFIGS.CONTROLLER_MANAGEMENT.title },
				loadChildren: () => import("./modules/ControllerManagement/controller-management.module").then((m) => m.ControllerManagementModule)
			},
			{
				path: `:id${ROUTE_CONFIGS.VPN_MANAGEMENT.route}`,
				canActivate: [AuthGuard],
				canActivateChild: [AuthGuard],
				data: { breadcrumb: ROUTE_CONFIGS.VPN_MANAGEMENT.title },
				loadChildren: () => import("./modules/VPNManagement/vpn-management.module").then((m) => m.VpnManagementModule)
			},
			{
				path: `:id${ROUTE_CONFIGS.APPLICATION_MANAGEMENT.route}`,
				canActivate: [AuthGuard],
				canActivateChild: [AuthGuard],
				data: { breadcrumb: ROUTE_CONFIGS.APPLICATION_MANAGEMENT.title },
				loadChildren: () => import("./modules/ApplicationManagement/application-management.module").then((m) => m.ApplicationManagementModule)
			},
			{
				path: `:id${ROUTE_CONFIGS.KUBERNETES_MANAGEMENT.route}`,
				canActivate: [AuthGuard],
				canActivateChild: [AuthGuard],
				data: { breadcrumb: ROUTE_CONFIGS.KUBERNETES_MANAGEMENT.title },
				loadChildren: () => import("./modules/ServiceManagement/service-management.module").then((m) => m.ServiceManagementModule)
			},
			{
				path: `:id${ROUTE_CONFIGS.OPENSTACK_MANAGEMENT.route}`,
				canActivate: [AuthGuard],
				canActivateChild: [AuthGuard],
				data: { breadcrumb: ROUTE_CONFIGS.OPENSTACK_MANAGEMENT.title },
				loadChildren: () => import("./modules/OpenstackServiceManagement/openstack-service-mng.module").then((m) => m.OpenstackServiceMngModule)
			},
			{
				path: `:id${ROUTE_CONFIGS.LICENSE_MANAGEMENT.route}`,
				canActivate: [AuthGuard],
				canActivateChild: [AuthGuard],
				data: { breadcrumb: ROUTE_CONFIGS.LICENSE_MANAGEMENT.title },
				loadChildren: () => import("./modules/licenses/licenses.module").then((m) => m.LicensesModule)
			},
			{
				path: `:id${ROUTE_CONFIGS.NOTIFICATIONS.route}`,
				canActivate: [AuthGuard],
				canActivateChild: [AuthGuard],
				data: { breadcrumb: ROUTE_CONFIGS.NOTIFICATIONS.title },
				loadChildren: () => import("./modules/Notifications/notifications.module").then((m) => m.NotificationsModule)
			},
			{
				path: `:id${ROUTE_CONFIGS.DISCOVERY.route}`,
				canActivate: [AuthGuard],
				canActivateChild: [AuthGuard],
				data: { breadcrumb: ROUTE_CONFIGS.DISCOVERY.title },
				loadChildren: () => import("./modules/Discovery/discovery.module").then((m) => m.DiscoveryModule)
			},
			{
				path: `:id${ROUTE_CONFIGS.ADMINISTRATION.route}`,
				canActivate: [AuthGuard],
				canActivateChild: [AuthGuard],
				data: { breadcrumb: ROUTE_CONFIGS.ADMINISTRATION.title },
				loadChildren: () => import("./modules/Administration/administration.module").then((m) => m.AdministrationModule)
			},
		],
	},
	{
		path: "mobile",
		component: MobileEntryComponent,
		children: [
			{
				path: "DashboardMobile",
				canActivate: [AuthGuard],
				component: DashboardMobileComponent,
				data: { breadcrumb: { skip: true } },
				children: []
			},
			{
				path: "Notifications",
				canActivate: [AuthGuard],
				canActivateChild: [AuthGuard],
				data: { breadcrumb: { disable: true } },
				loadChildren: () => import("./mobile/Notifications/notifications-mobile.module").then((m) => m.NotificationsMobileModule)
			}
		]
	},
	{ path: '**', redirectTo: '/Login' },

];

export const routing = RouterModule.forRoot(routes, {
	useHash: true,
	onSameUrlNavigation: "reload",
	canceledNavigationResolution: 'computed',
	paramsInheritanceStrategy: 'emptyOnly',
	urlUpdateStrategy: 'deferred',
});