import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceService } from 'services/resource.service';
import { Customer, Operator } from 'src/app/models';
import { Response } from 'src/app/models/types/common';

type NamespaceEntry = { projects: any; operators: Response<Operator>; customers: Response<Customer>; namespace: any; };

@Injectable({
  providedIn: 'root'
})
export class NamespaceService {
  namespaceEntry = {};
  namespacesIDs: number[] = [];
  selectedIndex = null;
  constructor(private http: HttpClient, private resourceService: ResourceService) {
  }

  getNamespaceById(id): Observable<any> {
    return this.http.get(this.resourceService.getApiUrl() + `/k8s/namespace/${id}`);
  }

  saveNamespace(value): Observable<any> {
    return this.http.put(this.resourceService.getApiUrl() + '/k8s/namespace', value);
  }

  updateNamespace(value, id): Observable<any> {
    return this.http.post(this.resourceService.getApiUrl() + `/k8s/namespace/${id}`, value);
  }

  delete(id): Observable<any> {
    return this.http.post(this.resourceService.getApiUrl() + `/k8s/namespace/advanced`, {
      delete: id
    });
    // return this.http.delete(this.resourceService.getApiUrl() + `/k8s/namespace/${id}`);
  }

  saveEntry(namespace: NamespaceEntry, id) {
    this.namespaceEntry[id] = namespace;
  }

  attachNamespaces(namespaceIDs: number[], controllerIDs: number[]): Observable<any> {
    return this.http.post(this.resourceService.getApiUrl() + `/k8s/namespace/advanced`, {
      attach: namespaceIDs,
      controller_list: controllerIDs
    });

  }

  detachNamespaces(namespaceIDs: number[], controllerIDs: number[]): Observable<any> {
    return this.http.post(this.resourceService.getApiUrl() + `/k8s/namespace/advanced`, {
      detach: namespaceIDs,
      controller_list: controllerIDs
    });
  }

  getNamespaceParams(): Observable<any> {
    return this.http.get(this.resourceService.getApiUrl() + `/k8s/namespace/params`);
  }
}
