import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { CookieService } from 'ngx-cookie-service';
import { iif, Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AlarmsAndEventsService } from 'services/alarmsAndEvents.service';
import { ENTRY, SseService } from 'services/sse.service';
import { AcknowledgeDialogComponent } from 'src/app/components/custom-components/acknowledge-dialog/acknowledge-dialog.component';
import { exhaustMapWithTrailing } from 'src/app/components/helpers/rxjs.exhaustmap.with.trailing';
import { PaginatorInfo, Sorted } from 'src/app/models';
import { TableDataResult } from 'src/app/models/interfaces/dataTableResult';
import { SelectionItem } from 'src/app/models/interfaces/SelectionItem';
import { DashboardService } from 'src/app/services';
import { Alert } from '../../../../models/Alert';
import { CustomTableComponent } from '../../../custom-components/custom-table/custom-table.component';


@Component({
  selector: 'app-alerts-dropdown',
  templateUrl: './alerts-dropdown.component.html',
  styleUrls: [ './alerts-dropdown.component.scss' ]
})
export class AlertsDropdownComponent implements OnInit, OnDestroy {
  @ViewChild(CustomTableComponent) customTableComponent;
  @ViewChild(MatMenuTrigger) triggerAlarmDropdown: MatMenuTrigger;
  alerts: Alert[];
  severity = 'critical';
  initialColumns: string[] = [ 'select', 'create_time', 'module', 'description' ];
  tableData$: any;
  pageCount: number;
  currentSort: Sorted = new Sorted();
  paginatorInfo: PaginatorInfo;
  selected: SelectionItem[] = [];
  isAllChecked: boolean;
  showAckBtn: boolean = true;
  tableName = 'alertsDropdown';
  private $$sseStream: Observable<any>;
  private $$sseStreamSubscription: Subscription;

  @Input() isExpanded;

  constructor(private dashboardService: DashboardService,
              private cookies: CookieService,
              private alarmsAndAlarmService: AlarmsAndEventsService,
              private dialog: MatDialog,
              private sseService: SseService
  ) {
  }

  ngOnDestroy(): void {
    this.$$sseStreamSubscription.unsubscribe();
  }

  ngOnInit(): void {
    if (this.cookies.get('is_readonly') == 'true') {
      this.showAckBtn = false;
    }

    this.$$sseStream = this.sseService.es$.pipe(
      filter((x: any) => x.includes(ENTRY.ALERT) || x.includes(ENTRY.RECONNECT)),
      exhaustMapWithTrailing(() => this.eventSourceRefreshTable())
    );
    this.$$sseStreamSubscription = this.$$sseStream.subscribe();
  }

  eventSourceRefreshTable(): Observable<any> {
    return this.alarmsAndAlarmService.getAlertsAdvanced(this.paginatorInfo?.pageIndex + 1 || 1,
      this.paginatorInfo?.pageSize || 15, this.currentSort?.order || 'create_time', this.currentSort?.direction || 'desc', true, []).pipe(
      map((result: TableDataResult) => {
        console.log('updated from SSE event', ENTRY.ALERT);
        this.tableData$ = result.data;
        this.pageCount = result.count;
      })
    );
  }

  refreshTable() {
    return this.alarmsAndAlarmService.getAlertsAdvanced(this.paginatorInfo?.pageIndex + 1 || 1,
      this.paginatorInfo?.pageSize || 15, this.currentSort?.order || 'create_time', this.currentSort?.direction || 'desc', true, []).subscribe((result: TableDataResult) => {
      this.tableData$ = result.data;
      this.pageCount = result.count;
    });
  }

  acknowledgeAlarm() {
    if (this.selected.length > 0) {
      const isAllChecked = this.isAllChecked;
      const selectedItems = this.selected;

      this.openAcknowledgeDialog('acknowledge', 'edge event', 'Acknowedge', '').pipe(
        filter(res => res != 'cancel'),
        switchMap((reason): any => {
          return iif(() => isAllChecked,
            this.alarmsAndAlarmService.getBulkAlertIds(true, [ { columnName: 'Acknowledged', filterValue: 'false' } ])
              .pipe(
                map(ids => {
                  return { reason, ids };
                })
              ),
            of({
              reason: reason, ids: {
                id_list: selectedItems.map(item => {
                  return item.id;
                })
              }
            })
          );
        }),
        switchMap(res => {
          return this.alarmsAndAlarmService.doAcknowledgeAlerts(1, 20, res['ids']['id_list'], res['reason']);
        }),
        tap(() => {
            this.customTableComponent.clearTableSelection();
            this.refreshTable();
          }
        )
      ).subscribe();
    }
  }

  public openAcknowledgeDialog(message: string, alert_type: string, confirm?: string, cancel?: string): Observable<any> {
    if (this.dialog.openDialogs.length > 0) {
      return;
    }

    const dialogRef = this.dialog.open(
      AcknowledgeDialogComponent,
      {
        data: {
          message,
          alert_type,
          buttonText: { confirm, cancel },
        }, disableClose: true
      });

    return dialogRef.afterClosed();
  }
}
