import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

//https://brajrajagrawal.medium.com/boosting-angular-app-performance-with-routereusestrategy-ccaa5e043586#:~:text=What%20is%20Router%20Reuse%20Strategy,cached%20and%20under%20what%20conditions.
// Needs more exploring

export class CustomReuseStrategy implements RouteReuseStrategy {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (future.routeConfig !== curr.routeConfig) {
      return false; // Do not reuse the route if we're switching to a different route
    }

    // Check if any parameter or query parameter changes
    let keysA = Object.keys(future.params);
    let keysB = Object.keys(curr.params);
    if (keysA.length != keysB.length || keysA.some(k => future.params[k] !== curr.params[k])) {
      return false;
    }

    keysA = Object.keys(future.queryParams);
    keysB = Object.keys(curr.queryParams);
    if (keysA.length != keysB.length || keysA.some(k => future.queryParams[k] !== curr.queryParams[k])) {
      return false;
    }

    return true;
  }
}