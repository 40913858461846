import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'columnFormatter',
})
export class ColumnFormatterPipe implements PipeTransform {
  readonly adminStatusTableNames = {
    ControllerDiscovery: 'Controller Admin Status',
    ControllerManagement: 'Controller Admin Status',
    ControllerEventsTable: 'Controller Admin Status',
    VPNManagement: 'VPN Management',
    'DeployedServices/deployModeTrue': 'Service Status',
    'EdgeDevices/ApplicationManagement': 'Service Admin Status',
  };

  readonly operStatusTableNames = {
    'EdgeDevices/ApplicationManagement': 'Service Oper Status',
  };

  readonly statusTable = {
    ControllerDiscovery: 'Controller Oper Status',
    ControllerManagement: 'Controller Oper Status',
    ControllerEventsTable: 'Controller Oper Status',
    UserActionsTable: 'Status',
    backupHistory: 'Status',
    restoreHistory: 'Status',
  };

  constructor(private titlecasePipe: TitleCasePipe) {}

  transform(column: string, tableName: string): string {
    const format = {
      busy: 'configuring',
      uid: 'serial_number',
      cidr: 'CIDR',
      'ip_version': 'IP Version',
      is_active: 'Active',
      hwtype: 'Hardware_Type',
      ram: tableName == 'FlavorCatalog' ? 'RAM (MB)' : 'RAM',
      management_ips:
        tableName == 'ControllerDiscovery' ||
        tableName == 'ControllerManagement' ||
        tableName == 'ControllerEventsTable'
          ? 'Controller_Management_IPs'
          : 'Device_Management_IPs',
      controller_management_ips: 'controller_management_IPs',
      end_time: ['restoreHistory'].includes(tableName)
        ? 'End Time'
        : 'Expiration Date',
      cpu: tableName!='NamespaceCatalog' && 'CPU Limits',
      license_cpu: 'License CPUs',
      cpus: 'CPUs',
      cpu_model: 'CPU Model',
      features: 'Functionalities',
      hdd: 'HDD',
      app_list: 'Application_List',
      is_readonly: 'Permissions',
      alarm_description: 'Description',
      app_catalog_name: 'Application Name',
      app_catalog_version: 'Application Version',
      app_node_admin_status: 'Application Admin Status',
      app_node_oper_status: 'Application Oper Status',
      nodes:'Devices',
      admin_status:
        this.adminStatusTableNames[tableName] || 'Device Admin Status',
      oper_status:
        this.operStatusTableNames[tableName] || 'Service Oper Status',
      app_description:
        tableName == 'EdgeDevices/ApplicationManagement'
          ? 'Description'
          : this.titlecasePipe.transform(column),
      description:
        tableName == 'ImageCatalog'
          ? 'Image Description'
          : tableName == 'networkCatalog' ? 'Network Description' : this.titlecasePipe.transform(column),
      status: this.statusTable[tableName] || 'Device Oper Status',
      backup_name: 'Name',
      name:
        tableName == 'DeployedServices/deployModeTrue' ||
        tableName == 'EdgeDevices/ApplicationManagement'
          ? 'Service Name'
          : this.titlecasePipe.transform(column),
      k8s_service_name: 'Service Name',
      k8s_service_namespace: 'Service Namespace',
      max_shared: 'max shared',
      k8s_service_description: 'Service Description',
      k8s_service_oper_status:'Service Oper Status',
      k8s_service_template_name: 'Service Template Name',
      k8s_service_admin_status: 'Service Admin Status',

      app_service_name: 'Service Name',
      app_service_description: 'Service Description',
      app_service_admin_status: 'Service Admin Status',
      app_service_oper_status: 'Service Oper Status',
      app_service_template_name: 'Service Template Name',
      app_service_template_description: 'Service Template Description',
      apps: 'Service Applications',
      app_service_apps: 'Service Applications',

      os_service_name: 'Service Name',
      os_service_description: 'Service Description',
      os_service_template_name: 'Service Template Name',
      os_service_admin_status: 'Service Admin Status',
      os_service_template_description: 'Service Template Description',
      os_service_params: 'Service Params',
      is_ignored: 'Blacklisted',
      is_ack: 'Acknowledged',
      ack_reason: 'Acknowledge reason',
      // Acknowledge Time to be removed by Markov's request: NFV-19131
      //ack_time:'Acknowledge Time',
      auth_type_text: 'Authentication Type',
      remote_url: 'Remote URL',
      node_hostname: 'Node',
      hostname:
      tableName === 'VPNManagement' ? 'Concentrator Endpoint' : '',
      ip_address: 'Concentrator API Endpoint',
      license_end_time: 'License End Date',
      license_start_time: 'License Start Date',
      start_time: [ 'restoreHistory' ].includes(tableName)
        ? 'Start Time'
        : tableName == 'backupHistory' ? 'Start Date From' : 'Start Date',
      size: 'Size (MB)',
      vcpus: 'Virtual CPUs',
      disk: 'Disk size (GB)',
      is_cluster: 'Is HA',
      k8s_service_backup_profile_name: 'Backup Profile',
      backup_profile_name: 'Backup Profile Name',
      'Backup Name': 'Name',
      backup: 'Backup Status',
      backup_properties: 'properties',
      scheduler_describe: 'Scheduler GMT'
    };
    return (format[column] || this.titlecasePipe.transform(column)).replace(
      /_/gi,
      ' '
    );
  }
}
