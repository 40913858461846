import { ROUTE_CONFIGS } from "src/app/models/constants/route-paths.constants";

export const ITEMS = [{
  name: ROUTE_CONFIGS.DASHBOARD.title,
  icon: 'dashboard',
  routerLink: ROUTE_CONFIGS.DASHBOARD.route,
  theChosen: false
}, {
  name: ROUTE_CONFIGS.EDGE_MANAGEMENT.title,
  icon: 'cloud',
  routerLink: ROUTE_CONFIGS.EDGE_DEVICES.route,
  showSubmenu: false,
  theChosen: false,
  children: [
    { name: ROUTE_CONFIGS.EDGE_DEVICES.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.EDGE_DEVICES.route },
    { name: ROUTE_CONFIGS.OPERATORS.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.OPERATORS.route },
    { name: ROUTE_CONFIGS.CUSTOMERS.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.CUSTOMERS.route },
    { name: ROUTE_CONFIGS.SERVICES.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.SERVICES.route },
    { name: ROUTE_CONFIGS.SITES.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.SITES.route },
    { name: ROUTE_CONFIGS.HARDWARE_TYPES.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.HARDWARE_TYPES.route },
    { name: ROUTE_CONFIGS.EDGE_TEMPLATES.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.EDGE_TEMPLATES.route }
  ]
}, {
  name: ROUTE_CONFIGS.CONTROLLER_MANAGEMENT.title,
  icon: 'dns',
  routerLink: ROUTE_CONFIGS.CONTROLLERS.route,
  showSubmenu: false,
  theChosen: false,
  children: [
    { name: ROUTE_CONFIGS.CONTROLLERS.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.CONTROLLERS.route },
  ]
}, {
  name: ROUTE_CONFIGS.VPN_MANAGEMENT.title,
  routerLink: ROUTE_CONFIGS.VPN_CONCENTRATORS.route,
  icon: 'wifi_tethering',
  showSubmenu: false,
  theChosen: false,
  children: [
    { name: ROUTE_CONFIGS.VPN_CONCENTRATORS.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.VPN_CONCENTRATORS.route },
  ]
}, {
  name: ROUTE_CONFIGS.APPLICATION_MANAGEMENT.title,
  icon: 'extension',
  routerLink: ROUTE_CONFIGS.APPLICATION_CATALOG.route,
  showSubmenu: false,
  theChosen: false,
  children: [
    { name: ROUTE_CONFIGS.APPLICATION_CATALOG.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.APPLICATION_CATALOG.route },
    { name: ROUTE_CONFIGS.APPLICATION_TEMPLATES_CATALOG.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.APPLICATION_TEMPLATES_CATALOG.route },
    { name: ROUTE_CONFIGS.APPLICATION_EDGE_SERVICES.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.APPLICATION_EDGE_SERVICES.route },
    { name: ROUTE_CONFIGS.APPLICATION_DESIGNER.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.APPLICATION_DESIGNER.route },
  ]
}, {
  name: ROUTE_CONFIGS.KUBERNETES_MANAGEMENT.title,
  svgIcon: 'kubernetes',
  routerLink: ROUTE_CONFIGS.KUBERNETES_CATALOG.route,
  showSubmenu: false,
  theChosen: false,
  children: [
    { name: 'Namespaces Catalog', theChosenChild: false, routerLink: '/Kubernetes Service Management/Namespaces' },
    { name: ROUTE_CONFIGS.KUBERNETES_CATALOG.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.KUBERNETES_CATALOG.route },
    { name: ROUTE_CONFIGS.KUBERNETES_TEMPLATES_CATALOG.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.KUBERNETES_TEMPLATES_CATALOG.route },
    { name: ROUTE_CONFIGS.KUBERNETES_BACKUP_PROFILES.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.KUBERNETES_BACKUP_PROFILES.route },
    { name: ROUTE_CONFIGS.KUBERNETES_BACKUP_HISTORY.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.KUBERNETES_BACKUP_HISTORY.route },
    { name: ROUTE_CONFIGS.KUBERNETES_RESTORE_BACKUPS.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.KUBERNETES_RESTORE_BACKUPS.route },
    { name: ROUTE_CONFIGS.KUBERNETES_EDGE_SERVICES.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.KUBERNETES_EDGE_SERVICES.route },
    { name: ROUTE_CONFIGS.KUBERNETES_DESIGNER.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.KUBERNETES_DESIGNER.route },
    { name: 'Helm Charts', theChosenChild: false, routerLink: '/Kubernetes Service Management/HelmCharts' },
  ]
}, {
  name: ROUTE_CONFIGS.OPENSTACK_MANAGEMENT.title,
  svgIcon: 'openstack',
  routerLink: ROUTE_CONFIGS.OPENSTACK_CATALOG.route,
  showSubmenu: false,
  theChosen: false,
  children: [
    { name: ROUTE_CONFIGS.OPENSTACK_CATALOG.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.OPENSTACK_CATALOG.route },
    { name: ROUTE_CONFIGS.OPENSTACK_FLAVOR_CATALOG.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.OPENSTACK_FLAVOR_CATALOG.route },
    { name: ROUTE_CONFIGS.OPENSTACK_NETWORKS_CATALOG.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.OPENSTACK_NETWORKS_CATALOG.route },
    { name: ROUTE_CONFIGS.OPENSTACK_TEMPLATES_CATALOG.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.OPENSTACK_TEMPLATES_CATALOG.route },
    { name: ROUTE_CONFIGS.OPENSTACK_EDGE_SERVICES.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.OPENSTACK_EDGE_SERVICES.route },
    { name: ROUTE_CONFIGS.OPENSTACK_DESIGNER.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.OPENSTACK_DESIGNER.route },
  ]
}, {
  name: ROUTE_CONFIGS.LICENSE_MANAGEMENT.title,
  icon: 'done_all',
  showSubmenu: false,
  theChosen: false,
  routerLink: ROUTE_CONFIGS.LICENSE_LICENSES.route,
  children: [
    { name: ROUTE_CONFIGS.LICENSE_LICENSES.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.LICENSE_LICENSES.route },
    { name: ROUTE_CONFIGS.LICENSE_SUMMARY.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.LICENSE_SUMMARY.route }
  ]
}, {
  name: ROUTE_CONFIGS.NOTIFICATIONS.title,
  icon: 'notification_important',
  routerLink: ROUTE_CONFIGS.NOTIFICATIONS_EDGE_EVENTS.route,
  theChosen: false,
  children: [
    { name: ROUTE_CONFIGS.NOTIFICATIONS_EDGE_EVENTS.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.NOTIFICATIONS_EDGE_EVENTS.route },
    { name: ROUTE_CONFIGS.NOTIFICATIONS_CONTROLLER_EVENTS.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.NOTIFICATIONS_CONTROLLER_EVENTS.route },
    { name: ROUTE_CONFIGS.NOTIFICATIONS_KUBERNETES_SERVICE_EVENTS.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.NOTIFICATIONS_KUBERNETES_SERVICE_EVENTS.route },
    { name: 'Helm Chart Events', theChosenChild: false, routerLink: '/Notifications/HelmChartEvents' },
    { name: ROUTE_CONFIGS.NOTIFICATIONS_OPENSTACK_SERVICE_EVENTS.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.NOTIFICATIONS_OPENSTACK_SERVICE_EVENTS.route },
    { name: ROUTE_CONFIGS.NOTIFICATIONS_SYSTEM_EVENTS.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.NOTIFICATIONS_SYSTEM_EVENTS.route },
    { name: ROUTE_CONFIGS.NOTIFICATIONS_USER_OPERATIONS.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.NOTIFICATIONS_USER_OPERATIONS.route },
    { name: ROUTE_CONFIGS.NOTIFICATIONS_ALERTS.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.NOTIFICATIONS_ALERTS.route }
  ]
}, {
  name: ROUTE_CONFIGS.DISCOVERY.title,
  icon: 'search',
  routerLink: ROUTE_CONFIGS.DISCOVERY_EDGE_DEVICES.route,
  children: [
    { name: ROUTE_CONFIGS.DISCOVERY_EDGE_DEVICES.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.DISCOVERY_EDGE_DEVICES.route },
    { name: ROUTE_CONFIGS.DISCOVERY_CONTROLLERS.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.DISCOVERY_CONTROLLERS.route },
  ]
}, {
  name: ROUTE_CONFIGS.ADMINISTRATION.title,
  icon: 'people',
  routerLink: ROUTE_CONFIGS.ADMINISTRATION_PROJECTS.route,
  showSubmenu: false,
  theChosen: false,
  children: [
    { name: ROUTE_CONFIGS.ADMINISTRATION_PROJECTS.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.ADMINISTRATION_PROJECTS.route },
    { name: ROUTE_CONFIGS.ADMINISTRATION_SYSTEM_MONITORING.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.ADMINISTRATION_SYSTEM_MONITORING.route },
    { name: ROUTE_CONFIGS.ADMINISTRATION_USERS.title, theChosenChild: false, routerLink: ROUTE_CONFIGS.ADMINISTRATION_USERS.route },
  ]
}];
