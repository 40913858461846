import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(public cookieService: CookieService, private router: Router) {
  }

  getItem(item: string): any {
    return JSON.parse(sessionStorage.getItem(item)) || {};
  }

  setItem(user: string, value: any): void {
    sessionStorage.setItem(user, JSON.stringify(value));
  }

  removeItem(item: string): void {
    const currentPage = this.router.url;
    const user = this.cookieService.get('user');
    const lookingFor = this.getItem(user);
    if (!lookingFor[currentPage]) {
      return;
    }
    delete lookingFor[currentPage][item];
    this.setItem(user, lookingFor);
  }

  patchItemValue(user, item: any, value: any, page: any) {
    const current = this.getItem(user);

    this.setItem(user, { ...current, [page]: { ...current[page], [item]: { ...value[page] } } });
  }

  saveSort(page, user, item, val) {
    let currentPage = this.getItem(user);
    if (!currentPage[page]) {
      currentPage = { [page]: { [item]: val }, ...currentPage };
    }
    currentPage[page][item] = val;

    this.setItem(user, currentPage);
  }

}
