<div class="alerts" [matMenuTriggerFor]="menu" *ngIf="isExpanded">
  <div class="icon-label-container">
    <mat-icon>notifications</mat-icon>

    <div class="label" style="{{!isExpanded ? 'display: none;' : 'display: flex;'}}">
      Alerts
    </div>
  </div>

  <span style="{{pageCount == 0 ? 'display: none;' : 'padding: .2rem .9rem .2rem .9rem; border-radius: 5rem; font-size: 1.4rem;'}}"
      [ngClass]="severity">
      {{pageCount == 0 ? '' : pageCount }}
  </span>
</div>

<div *ngIf="!isExpanded" class="alerts alerts-expanded" [matMenuTriggerFor]="menu" [matTooltipShowDelay]="800" matTooltipClass="tooltipLook" matTooltip="Alerts">
  <mat-icon>notifications</mat-icon>
  <span
    style="{{pageCount == 0 ? 'display: none;' : 'padding: .2rem .9rem .2rem .9rem; border-radius: 5rem; font-size: 1.4rem; margin-bottom: 2rem; margin-left: -1rem;'}}"
    [ngClass]="severity">{{pageCount == 0 ? '' : pageCount < 100 ? pageCount : "99+"}}</span>
</div>

<mat-menu #menu="matMenu" [class]="'alertDropDown'">
  <mat-card *ngIf='pageCount != 0'>
    <div style=" display: flex; flex-direction: column;position: relative;">
      <div (click)="$event.stopPropagation()" style="height: 40rem; width: 85rem; overflow-x: hidden;">
        <app-custom-table
          [telcoKeepSort]="this"
          [tableName]="'alertsDropdown'"
          cdkDropList cdkDropListOrientation="horizontal"
          #table
          matSort #sort="matSort"
          [initialColumns]='initialColumns'
          [dataSource]='tableData$'
          matSortStart="asc"
          [matSortDisableClear]="true"
          [pageCount]='pageCount'
          class="table_responsive">
          <ng-container [matColumnDef]="column" *ngFor="let column of initialColumns | slice:1:initialColumns.length">
            <th style="padding: 5px" mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> {{column | columnFormatter:'' }}</th>
            <td style="padding: 5px" mat-cell *matCellDef="let row">
              <telco-description-case [column]="column"[row]="row"></telco-description-case>
            </td>
          </ng-container>
        </app-custom-table>
      </div>

      <div style="margin-top: 1rem; display: flex; justify-content: end;">
        <button *ngIf="showAckBtn" mat-flat-button
                [ngClass]="{'acknowledgeButton': table.selection.selected.length}"
                (click)="acknowledgeAlarm()" [disabled]="!table.selection.selected.length">
          <mat-icon>assignment_turned_in</mat-icon>
          Acknowledge
        </button>
        &nbsp;
      </div>
    </div>
  </mat-card>


  <mat-card *ngIf='pageCount == 0'>
    <div>
      <p>No active alerts</p>
    </div>
  </mat-card>

</mat-menu>
