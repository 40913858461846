<!-- <button mat-icon-button *ngIf="!isExpanded" class="badge-block" [matMenuTriggerFor]="menu" #t="matMenuTrigger">
  <mat-icon
    [matTooltipShowDelay]="800"
    matTooltipClass="tooltipLook"
    matTooltip="User operations"
    [matBadge]="pageCount == 0 ? '99+' : pageCount < 100 ? pageCount : '99+'"
    matBadgeOverlap="false">
    play_circle_outline
  </mat-icon>
</button> -->


<div class="operations" [matMenuTriggerFor]="menu" *ngIf="isExpanded">
  <div class="icon-label-container">
    <mat-icon>play_circle_outline</mat-icon>

    <div class="label" style="{{!isExpanded ? 'display: none;' : 'display: flex;'}}">
      User Operations
    </div>
  </div>

  <span class="badge-block" style="{{pageCount == 0 ? 'display: none;' : 'padding: .2rem .9rem .2rem .9rem; border-radius: 5rem; font-size: 1.4rem;'}}">
    {{pageCount == 0 ? '' : pageCount }}
  </span>
</div>

<div *ngIf="!isExpanded" class="operations operations-expanded" [matMenuTriggerFor]="menu" [matTooltipShowDelay]="800" matTooltipClass="tooltipLook" matTooltip="User Operations">
  <mat-icon>play_circle_outline</mat-icon>
  <span class="badge-block"
    style="{{pageCount == 0 ? 'display: none;' : 'padding: .2rem .9rem .2rem .9rem; border-radius: 5rem; font-size: 1.4rem; margin-bottom: 2rem; margin-left: -1rem;'}}">
    {{pageCount == 0 ? '' : pageCount < 100 ? pageCount : "99+"}}
  </span>
</div>

<mat-menu #menu="matMenu" [class]="'alertDropDown'">
  <mat-card appearance="outlined" *ngIf='pageCount != 0'>
    <div style=" display: flex; flex-direction: column;position: relative;">
      <div (click)="$event.stopPropagation()" style="max-height: 40rem; width: 90rem; overflow-x: hidden;">
        <telco-custom-dropdown-table #table [initialColumns]="initialColumns" [pageCount]='pageCount' [dataSource]="tableData$"
          (newItemEvent)="getSelectedElement($event)" (sortChangeEvent)="onSortChanged($event)"
          (allItemCheckedEvent)="isAllCheckboxCheckedControl($event)"
          (pageChangeEvent)="onPageChanged($event)"></telco-custom-dropdown-table>
      </div>
      <div style="margin-top: 1rem; display: flex; justify-content: end;">
        <button *ngIf="showAckBtn" class="acknowledgeButton" mat-flat-button (click)="acknowledgeAlarm($event)" [disabled]="!table.selection.selected.length">
          <mat-icon>assignment_turned_in</mat-icon>
          Acknowledge
        </button>
        &nbsp;
        <button class="clearButton" mat-flat-button (click)="cancelAction($event)" [disabled]="!table.selection.selected.length">
          <mat-icon>cancel</mat-icon>
          Cancel
        </button>
        &nbsp;
      </div>
    </div>
  </mat-card>

  <mat-card *ngIf='pageCount == 0'>
    <p style="font-size: 1.4rem;">No active user operations</p>
  </mat-card>
</mat-menu>
