import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Address4, Address6 } from 'ip-address';
import { BreadcrumbService } from "xng-breadcrumb";

const DOMAIN_REGEX = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/

export function randomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}


export function formatDateTime(dateTimeTimestamp: string, utc?: boolean) {
  const date = new Date(dateTimeTimestamp)

  if (date.toString() != 'Invalid Date') {
    if (utc) {
      const month = (date.getUTCMonth() + 1).toString().length === 1 ? `0${(date.getUTCMonth() + 1)}` : (date.getUTCMonth() + 1).toString()
      const day = date.getUTCDate().toString().length === 1 ? `0${(date.getUTCDate())}` : (date.getUTCDate()).toString()
      const hours = date.getUTCHours().toString().length === 1 ? `0${(date.getUTCHours())}` : (date.getUTCHours()).toString()
      const minutes = date.getUTCMinutes().toString().length === 1 ? `0${(date.getUTCMinutes())}` : (date.getUTCMinutes()).toString()
      const seconds = date.getUTCSeconds().toString().length === 1 ? `0${(date.getUTCSeconds())}` : (date.getUTCSeconds()).toString()
      return `${date.getUTCFullYear()}-${month}-${day} ${hours}:${minutes}:${seconds}`
    } else {
      const month = (date.getMonth() + 1).toString().length === 1 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1).toString()
      const day = date.getDate().toString().length === 1 ? `0${(date.getDate())}` : (date.getDate()).toString()
      const hours = date.getHours().toString().length === 1 ? `0${(date.getHours())}` : (date.getHours()).toString()
      const minutes = date.getMinutes().toString().length === 1 ? `0${(date.getMinutes())}` : (date.getMinutes()).toString()
      const seconds = date.getSeconds().toString().length === 1 ? `0${(date.getSeconds())}` : (date.getSeconds()).toString()
      return `${date.getFullYear()}-${month}-${day} ${hours}:${minutes}:${seconds}`
    }
  }
  else return ''
}


export function isValidIpv4AddressNoMask(ipv4: string) {
  return Address4.isValid(ipv4);
}

export function isValidIpv6AddressNoMask(ipv4: string) {
  return Address6.isValid(ipv4);
}

export function isIP6Adress(ip: string) {
  return ip.indexOf('::') > -1
}

export function isUndefined(element) {
  return element === null || element === undefined || Number.isNaN(element);
}

export function isEmpty(str: any): boolean {
  if (
    str == undefined ||
    str == null ||
    str.length == 0 ||
    str.toString().trim().length == 0
  )
    return true;
  return false;
}

export function isMulticastAddress(ip: string): boolean {
  const ipv4 = new Address4(ip);
  return Address4.isValid(ip) && ipv4.isInSubnet(new Address4('224.0.0.0/4'));
}

export function getIdsFromObject(objectArray: any[]) {
  return objectArray.map(object => { return object.id })
}

export function goToLink(url: string) {
  window.open(url, '_blank');
}

//FORM CONTROL VALIRATORS

export function isValidDomainFC(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = control.value == '' || DOMAIN_REGEX.test(control.value);
    return isValid ? null : { invalidDomain: { err: control.value } };
  };
}

export function isValidIpv4AddressNoMaskFC(optionalValidValues?: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = control.value == '' || (control.value && Address4.isValid(control.value)) || optionalValidValues?.includes(control.value);
    return isValid ? null : { invalidAddress: { err: control.value } };
  };
}

export function isValidExternalNameFC(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = control.value === '' || /^[a-zA-Z0-9.-]+$/.test(control.value);
    return isValid ? null : { invalidExternalName: { err: control.value } };
  };
}

export function validRunAsGroup(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value === null || value === undefined) {
      return null;
    }

    const isValid = Number.isInteger(value) && value >= 1000;
    return isValid ? null : { invalidRunAsGroup: true };
  };
}


export function isValidIpv4orDomainFC(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = control.value == '' || Address4.isValid(control.value) || DOMAIN_REGEX.test(control.value);
    return isValid ? null : { invalidAddressDomain: { err: control.value } };
  };
}

export function isValidMask(mask: number) {
  return 1 < mask && mask < 32
}

export function isValidIpv4AddressWithMaskFC(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const splitted = control.value.split('/')
    const ip = splitted[0]
    const mask = +splitted[1]
    const isValid = control.value == '' || (ip && mask && Address4.isValid(ip) && isValidMask(mask));
    return isValid ? null : { invalidAddress: { err: control.value } };
  };
}

/**
* Validate Range Example: 192.168.0.10-192.168.0.100
* return  { invalidRangeAddresses: { err: control.value } }
*/
export function isValidRangeAddressesFC(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const splitted = control.value.split('-')
    const ip1 = splitted[0]
    const ip2 = splitted[1]
    const isValid = control.value == '' || (ip1 && ip2 && Address4.isValid(ip1) && Address4.isValid(ip2));
    return isValid ? null : { invalidRangeAddresses: { err: control.value } };
  }
}

/**
* Validate Range Example: 192.168.0.10,192.168.0.11 ....
* return  { invalidRangeAddresses: { err: control.value } }
*/
export function isValidSequelAddressesFC(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const splitted = control.value.split(',')
    let invalid = false;
    splitted.forEach(ip => {
      !Address4.isValid(ip) && invalid == false ? invalid = true : null;
    })

    const isValid = control.value == '' || (!invalid);
    return isValid ? null : { invalidSeqAddresses: { err: control.value } };
  }
}

/**
* Validate  Example: 192.168.0.10,192.168.0.11 ....
* return  { invalidSeqAddresses: { err: control.value } }
*/

export function isValidSequelIP46AddressesFC(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const splitted = control.value.split(',')
    let invalid = false;
    splitted.forEach(ip => {
      !isValidIP46Adress(ip) && invalid == false ? invalid = true : null;
    })
    const isValid = control.value == '' || (!invalid);
    return isValid ? null : { invalidSeqAddresses: { err: control.value } };
  }
}

export function isValidIP46Adress(ip46: string): boolean {
  return Address6.isValid(ip46) || Address4.isValid(ip46);
}

export function isValidIpv46AddressNoMaskFC(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = control.value == '' || (control.value && isValidIP46Adress(control.value));
    return isValid ? null : { invalidAddress: { err: control.value } };
  };
}

export function isValidMAC(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // const regex = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/; for : nad - format
    const regex = /^([0-9a-zA-F]{2}[:]){5}([0-9a-zA-F]{2})$/;
    const isValid = control.value == '' || (control.value && regex.test(control.value));
    return isValid ? null : { invalidAddress: { err: control.value } };
  };

}

export function getRoundedNumber2Digits(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function isValidVrid() {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = control.value == '' || (+control.value < 256 && +control.value > 0);
    return isValid ? null : { invalidVrid: { err: control.value } };
  };
}

export function isValidVrrpPriority() {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = control.value == '' || (Number.isInteger(+control.value) && +control.value < 255 && +control.value > 0);
    return isValid ? null : { invalidPriority: { err: control.value } };
  };
}

export function setBreadcrumb(newBreadcrumb: string, breadcrumbService: BreadcrumbService) {
  const currentBreadcrumbs = (breadcrumbService as any).currentBreadcrumbs;
  const path = currentBreadcrumbs[currentBreadcrumbs.length - 1].routeLink;
  breadcrumbService.set(path, newBreadcrumb);
}

export function getBreadcrumbPath(breadcrumbService: BreadcrumbService) {
  const currentBreadcrumbs = (breadcrumbService as any).currentBreadcrumbs;
  return currentBreadcrumbs[currentBreadcrumbs.length - 1].routeLink;
}


export function jsonFormatter(jsonData: object, exceptsList: string[] = []) {
  const parsed = new Array<string>();
  for (const [key, value] of Object.entries(jsonData)) {
    exceptsList.includes(key) ? null : parsed.push(`${key}: ${value} \n`);
  }
  return parsed;
}

export function isValidInteger() {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = control.value == '' || (Number(+control.value) === +control.value && +control.value % 1 === 0);
    return isValid ? null : { invalidNumber: { err: control.value } };
  };
}

export function isValidFloat() {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = control.value == '' || (Number(+control.value) === +control.value && +control.value % 1 !== +control.value);
    return isValid ? null : { invalidNumber: { err: control.value } };
  };
}

export function isValidVlan(min: number = 0, max: number = 4095) {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = control.value == null || (Number(+control.value) > min && +control.value < max);
    return isValid ? null : { invalidVlan: { err: control.value } };
  };
}

export function isObjectEqual(object1: object, object2: object) {
  return JSON.stringify(object1) === JSON.stringify(object2);
}
