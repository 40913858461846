import { Pipe, PipeTransform } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Pipe({
  name: 'ifRole'
})
export class IfRolePipe implements PipeTransform {
  private readonly isSuperUser: boolean = !!+this.cookies.get('is_super_user');
  private readonly isAdminUser: boolean = this.cookies.get('role_id') == '0';
  private readonly isCustomerUser: boolean = this.cookies.get('role_id') == '2';
  sidebarChildren = {
    Projects: () => this.isAdminUser || !this.isSuperUser,
    Operators: () => this.isAdminUser,
    Customers: () => !this.isCustomerUser,
    'Project Settings': () => this.isAdminUser || !this.isSuperUser,
    'System Events': () => this.isSuperUser,
    'System Monitoring': () => this.isSuperUser
  };

  constructor(private cookies: CookieService) {
  }

  transform(value: string): boolean {
    return this.sidebarChildren[value]?.() ?? true;
  }
}
