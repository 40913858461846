<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>

<div class="image-background">
  <div style="position: absolute; top: 20%; left:10%">
    <div class="login-contents">
      <div class="logo"></div>
      <p class="title-message">Edge Management and Orchestration</p>
      <form [formGroup]="loginForm">
        <mat-label>Username</mat-label>
        <div class="unlucky-margin" style="margin-bottom: 15px">
          <mat-form-field>
            <input matInput formControlName="username" class="form-control" appAutoFocus>
          </mat-form-field>
        </div>

        <mat-label>Password</mat-label>
        <div class="unlucky-margin">
          <mat-form-field>
            <input matInput class="form-control" [type]="hide ? 'password' : 'text'" formControlName="password">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
              <mat-icon [ngStyle]="{'background-color': 'lightgrey'}">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
          </mat-form-field>

        </div>
        <button style="width: 36rem; margin-top: 1.5rem" mat-raised-button [disabled]="authenticationService.disableBtn" (click)="onSubmit()">
          Login
        </button>
      </form>
    </div>
  </div>
</div>
