import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Directive, HostListener, Input } from '@angular/core';

import { PaginatorInfo } from '../models';

@Directive({
  selector: 'app-custom-table'
})
export class CustomTableDirective {
  @Input() telcoKeepSort: any;

  @HostListener('cdkDropListDropped', [ '$event' ])
  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.telcoKeepSort.initialColumns, event.previousIndex, event.currentIndex);
    this.telcoKeepSort.columnIndex = [ ...this.telcoKeepSort.initialColumns ];
  }

  @HostListener('matSortChange', [ '$event' ])
  onSortChanged(event: any) {
    this.telcoKeepSort.currentSort.order = event.active;
    this.telcoKeepSort.currentSort.direction = event.direction || 'asc';

    this.telcoKeepSort.refreshTable();
  }

  @HostListener('defaultRefresh')
  onDefaultRefresh() {
    this.telcoKeepSort?.refreshTable();
  }

  @HostListener('newItemEvent', [ '$event' ])
  getSelectedElement(event) {
    this.telcoKeepSort.selected = event;
  }

  @HostListener('pageChangeEvent', [ '$event' ])
  onPageCahnged(event: PaginatorInfo) {
    if (this.telcoKeepSort) {
      this.telcoKeepSort.paginatorInfo = event;
      this.telcoKeepSort.refreshTable();
    }
  }

  @HostListener('allItemCheckedEvent', [ '$event' ])
  onAllItemCheckedEvent(event: any) {
    this.telcoKeepSort.isAllChecked = event.isAllChecked;
  }
}
