import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as echarts from 'echarts';
import { ECharts } from 'echarts';
import { TabService } from 'services/tab.service';
import { colors } from 'src/app/models';
import { DASHBOARD_ROUTE_CONFIGS } from '../models/dashboard-route-path.constants';

export class ServicesAllChartData {
  'not started': number;
  'started': number;
  'discovered': number; 
}

@Component({
  selector: 'telco-deployed-serv-admin-chart',
  templateUrl: './deployed-serv-admin-chart.component.html',
  styleUrls: ['./deployed-serv-admin-chart.component.scss']
})
export class DeployedServAdminChartComponent implements OnChanges, AfterViewInit {

  @ViewChild('servAdmChartSelector', { read: ElementRef, static: false }) servAdmChartSelector: ElementRef;
  @Input() servAdminTypesData: ServicesAllChartData;
  @Input() typeChart: string = '';
  @Input() serviceType: string; //application, k8s, openstack

  public customChart: any;
  public colors: typeof colors = colors;
  public liveView: boolean;

  selectedLegend: object;
  stopRecalculteOnRefresh: boolean = false;
  redirectUrl: string = 'Kubernetes Service Management/DeployedServices/DeployedServices';
  chartName: string = 'deployedServices';
  myChart: ECharts;
  entriesSum: number;
  chartTitle: string = '';

  constructor(private router: Router, private tabService: TabService) {
    this.liveView = false;
    this.entriesSum = 0;
  }

  ngAfterViewInit(): void {
    if (!this.servAdmChartSelector) return;

    this.myChart = echarts.init(this.servAdmChartSelector.nativeElement);
    this.myChart.on('legendselectchanged', function (params) {

      if (params['selected']) {
        this.selectedLegend = params['selected'];
        this.entriesSum = 0;

        for (const legentItem in params['selected']) {
          if (params['selected'][legentItem]) {
            const legentItemFormatted = legentItem.toLowerCase();

            if (this.servAdminTypesData[legentItemFormatted] != undefined) {
              this.entriesSum += this.servAdminTypesData[legentItemFormatted]
            }
          }
        }
      }

      this.myChart.setOption({
        title: {
          text: this.entriesSum,
          left: this.recenter(this.entriesSum),
          top: "center",
          textStyle: {
            fontSize: 16,
            align: 'center',
            color: colors.TEXT,
            fontFamily: colors.FONT,
          }
        }
      });
    }.bind(this));

    this.resizeChart();
    this.myChart.on('click', function (params) {
      let title: string;
      let path: string;
      if (this.serviceType == 'application') {
        title = DASHBOARD_ROUTE_CONFIGS.APP_EDGE_SERVICE_ADMIN_STATUS.title;
        path = DASHBOARD_ROUTE_CONFIGS.APP_EDGE_SERVICE_ADMIN_STATUS.route.replace(':name', params.name.toString());
      } else {
        title = DASHBOARD_ROUTE_CONFIGS.K8S_EDGE_SERVICE_ADMIN_STATUS.title;
        path = DASHBOARD_ROUTE_CONFIGS.K8S_EDGE_SERVICE_ADMIN_STATUS.route.replace(':state', params.name.toString()).replace(':name', 'null');
      }

      this.tabService.openTab(title, path);
    }.bind(this));
  }

  ngOnChanges(): void {
    if (this.servAdminTypesData != null) {
      this.initChart();
    }
    if (this.serviceType != null) {
      this.chartTitle = this.serviceType == 'application' ? 'Application' : 'Kubernetes';
    }
  }

  public initChart(): void {
    let newChartData: ServicesAllChartData = new ServicesAllChartData();

    if (this.selectedLegend) {
      for (const key in this.selectedLegend) {
        if (this.selectedLegend[key]) {
          const systemstatus = this.getStatusUItoSystem(key);
          newChartData[systemstatus] = this.servAdminTypesData[systemstatus];
        }
      }
    } else {
      newChartData = this.servAdminTypesData;
    }

    const initCount = this.calculateInit(newChartData);

    this.customChart = {
      title: {
        text: initCount,
        left: this.recenter(initCount),
        top: "center",
        textStyle: {
          fontSize: 16,
          align: 'center',
          color: colors.TEXT,
          fontFamily: colors.FONT,
        }
      },
      tooltip: {
        trigger: 'item',
        textStyle: {
          color: colors.TEXT,
          fontFamily: colors.FONT,
        }
      },
      legend: {
        top: 'center',
        left: 250,
        data: [{ name: 'Not Started', itemStyle: { color: colors.RED } }, { name: 'Started', itemStyle: { color: colors.GREEN } }, { name: 'Discovered', itemStyle: { color: colors.BLUE } }],
        textStyle: {
          color: colors.TEXT,
          fontFamily: colors.FONT,
        }
      },
      series: [{
        type: 'pie',
        radius: ['45%', '90%'],
        center: ['25%', '50%'],
        label: {
          show: true,
          position: 'inner',
          color: colors.TEXT,
          fontFamily: colors.FONT,
          formatter: (params) => {
            return params.value;
          }
        },
        labelLine: {
          normal: {
            show: false,
            //   position: 'inner',
            //   formatter: (params) => {
            //       return 10;
            //   }
          }
        },
        hoverAnimation: false,
        avoidLabelOverlap: false,

        itemStyle: {
          borderRadius: 5,
          borderColor: '#fff',
          // borderWidth: 1
        },

        data: this.convertNameValueArray(this.servAdminTypesData)
      }]
    };

    if (this.selectedLegend != null) {
      this.customChart['legend']['selected'] = this.selectedLegend;
      this.customChart['legend']['selectedMode'] = true;
      this.stopRecalculteOnRefresh = true;
    }
  }

  convertNameValueArray(chartData: ServicesAllChartData) {
    const newArrayData = [];

    if (chartData['not started']) {
      newArrayData.push({ name: 'Not Started', value: chartData['not started'], itemStyle: { color: colors.RED } });
    }

    if (chartData['started']) {
      newArrayData.push({ name: 'Started', value: chartData['started'], itemStyle: { color: colors.GREEN } });
    }

    if (chartData['discovered']) {
      newArrayData.push({ name: 'Discovered', value: chartData['discovered'], itemStyle: { color: colors.BLUE } });
    }

    this.resizeChart();
    return newArrayData
  }

  getStatusUItoSystem(status) {
    switch (status) {
      case 'Started': {
        return 'started';
      }
      case 'Not Started': {
        return 'not started';
      }
      case 'Discovered': {
        return 'not started';
      }
    }
  }

  calculateInit(edgeData) {
    let initValue = 0;

    if (edgeData['not started'] != undefined) {
      initValue = initValue + edgeData['not started'];
    }

    if (edgeData['started'] != undefined) {
      initValue = initValue + edgeData['started'];
    }

    if (edgeData['discovered'] != undefined) {
      initValue = initValue + edgeData['discovered'];
    }

    return initValue;
  }

  recenter(value: number) {
    if (value > -1 && value < 10) {
      return '22.5%'
    }

    if (value >= 10 && value < 100) {
      return '21.5%'
    }

    if (value >= 100 && value < 1000) {
      return '20.5%'
    }

    if (value >= 1000 && value < 10000) {
      return '19.5%'
    }

    if (value >= 10000 && value < 100000) {
      return '18.5%'
    }

    if (value >= 100000 && value < 1000000) {
      return '17.5%'
    }
  }

  onChartClick() {
    //console.log(event.name)
  }

  resizeChart() {
    if (this.myChart) {
      this.myChart.resize({ width: 382, height: 209 });
    }
  }
}
