import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AlertNotificationService } from 'services/alertNotification.service';
import { TabService } from 'services/tab.service';
import { TabState } from 'src/app/app.state';
import { NamespaceService } from '../../../modules/ServiceManagement/namespace-entry/namespace.service';

@Component({
  selector: 'telco-home-tabs',
  templateUrl: './home-tabs.component.html',
  styleUrls: ['./home-tabs.component.scss']
})
export class HomeTabsComponent implements OnInit {
  @Input() tabs$: Observable<TabState[]>;
  tabData: TabState[];
  selectedIndex: number = 0;

  private $destroyRef = inject(DestroyRef)

  constructor(
    private tabService: TabService, private alertNotificationService: AlertNotificationService, private store: Store, private namespaceService: NamespaceService,
  ) { }

  ngOnInit(): void {
    this.tabs$.pipe(takeUntilDestroyed(this.$destroyRef)).subscribe(data => {
      // const lastAddedTab = data[data.length - 1];
      // const lastActiveIndex = data.findIndex(tab => tab.id == lastAddedTab.currentActiveId);
      // const alreadyAdded = lastAddedTab && this.tabData && this.tabData.length > 0 ? this.tabData?.findIndex(tab => tab.id == lastAddedTab.id) : 0;

      //reorder only if last tab not exist
      // if (alreadyAdded == -1 && lastActiveIndex!=undefined && lastActiveIndex > -1) {
      //   data.splice(lastActiveIndex + 1, 0, lastAddedTab);
      //   data.splice(data.length - 1, 1);
      // }

      this.tabData = data;
      this.updateSelectedIndex();
    });
  }

  public switchTab(index: number): void {
    const selectedTab = this.tabData[index];
    if (selectedTab) {
      this.namespaceService.selectedIndex= selectedTab.id;
      this.tabService.switchTab(selectedTab.id);
      this.updateSelectedIndexById(selectedTab.id);
    }
  }

  public deleteTab(tab: TabState, event: MouseEvent, index): void {
    event.stopPropagation();
    const currentTabForm = this.tabService.getFormById(tab.id);
    if (currentTabForm && currentTabForm.touched) {
      this.alertNotificationService.alertGuardConfirm('Tab page').pipe(takeUntilDestroyed(this.$destroyRef)).subscribe(res => {
        if (res == true) {
          this.doDeleteTab(tab.id);
        }
      }
      );
    } else {
      this.doDeleteTab(tab.id);
    }

    if (this.namespaceService.namespaceEntry[index]) {
      this.namespaceService.namespaceEntry = {};
    }
  }

  private doDeleteTab(tabId) {
    if (this.namespaceService.namespaceEntry[tabId]) {
      delete this.namespaceService.namespaceEntry[tabId];
    }
    this.tabService.closeTab(tabId);
    this.updateSelectedIndex();
    this.tabService.deleteFormById(tabId);
  }
  private updateSelectedIndex(): void {
    if (this.tabData && this.tabData.length > 0) {
      const activeTabIndex = this.tabData.findIndex(tab => tab.isActive);
      this.selectedIndex = activeTabIndex !== -1 ? activeTabIndex : this.tabData.length - 1;
    }
  }

  private updateSelectedIndexById(id: string): void {
    const selectedIndex = this.tabData.findIndex(tab => tab.id === id);
    if (selectedIndex !== -1) {
      this.selectedIndex = selectedIndex;
    }
  }

  public clearAllTabs(): void {
    this.tabService.logoutUser();
  }

  public trackTab(index, tab: TabState) {
    return tab.id;
  }
}
