import { Component, EventEmitter, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-customize-bar',
  templateUrl: './customize-bar.component.html',
  styleUrls: ['./customize-bar.component.scss']
})
export class CustomizeBarComponent {
  ucpeStatusChart: boolean;
  discoveryChart: boolean;
  managerHealthChart: boolean;
  vnfStatusChart: boolean;
  vnfTypesChart: boolean;
  alarmsChart: boolean;
  controllerEventChart: boolean;
  activeOperChart: boolean;
  alertsChart: boolean
  controllerStatusChart: boolean;
  edgeAdminStatusChart: boolean;
  controllerDiscoveryChart: boolean;
  servicesChart: boolean;
  servicesAdminChart: boolean;
  helmServicesChart: boolean;
  kubernetesServicesChart = true;
  osServicesChart = true;

  @Output() eventChartView = new EventEmitter<any>();

  constructor() {
    this.ucpeStatusChart = true;
    this.managerHealthChart = true;
    this.vnfStatusChart = true;
    this.vnfTypesChart = true;
    this.alarmsChart = true;
    this.controllerEventChart = true;
    this.activeOperChart = true;
    this.alertsChart = true;
    this.controllerStatusChart = true;
    this.discoveryChart = true;
    this.edgeAdminStatusChart = true;
    this.controllerDiscoveryChart = true;
    this.servicesChart = false;
    this.servicesAdminChart = true;
    this.helmServicesChart = true;
  }

  onChangeCheckbox(state: MatCheckboxChange, chartName: string) {
    this.eventChartView.emit([state.checked, chartName]);
  }
}
