<div class="tab-container">
  <mat-tab-group [selectedIndex]="selectedIndex" (selectedIndexChange)="switchTab($event)">
    @for (tab of tabData; track tab) {
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="tab-label-container" matTooltipClass="tooltipLook" [matTooltipShowDelay]="800" matTooltip="{{ tab?.title || 'Loading...' }}">
            {{ tab?.title || 'Loading...' }}
          </span>
          <button (click)="deleteTab(tab, $event)" mat-icon-button class="close-tab-button">
            <mat-icon [class.active]="tab?.isActive" class="icon-btn">close</mat-icon>
          </button>
        </ng-template>
      </mat-tab>
    }
  </mat-tab-group>
  <button mat-icon-button (click)="clearAllTabs()" class="clear-all-tabs-btn" matTooltipClass="tooltipLook" [matTooltipShowDelay]="800" matTooltip='Close all tabs'>
    <mat-icon>delete_sweep</mat-icon>
  </button>
</div>