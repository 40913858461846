import { Action, ActionCreator, ActionReducer, createReducer, ReducerTypes } from '@ngrx/store';
import { TabState } from 'src/app/app.state';
import * as TabActions from '../actions/tab.actions';

export function createRehydrateReducer<State extends TabState[]>(
  key: string,
  initialState: State,
  ...ons: ReducerTypes<State, readonly ActionCreator[]>[]
): ActionReducer<State> {
  const savedState = sessionStorage.getItem(key);
  const newInitialState = savedState ? JSON.parse(savedState) : initialState;

  const reducer = createReducer(newInitialState, ...ons);

  return (state: State | undefined, action: Action): State => {
    // Clear localStorage on logout
    if (action.type === TabActions.logout.type) {
      sessionStorage.removeItem(key);
      return initialState as State;
    }
    if (action.type == '@ngrx/store/init' && newInitialState.length > 0) {
      return newInitialState;
    }

    // Calculate new state
    const newState = reducer(state, action);
    // Update localStorage with new state
    sessionStorage.setItem(key, JSON.stringify(newState));
    return newState;
  };
}