import { AfterContentInit, Directive, ElementRef, Input } from "@angular/core";

@Directive({
    selector: '[appAutoFocus]'
})

export class AutofocusDirective implements AfterContentInit {
    @Input() public autoFocus: boolean;

    constructor (private el: ElementRef) {}

    ngAfterContentInit(): void {
        setTimeout( () => {
            this.el.nativeElement.focus()
        }, 500)
    }
}