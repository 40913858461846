import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
	selector: "telco-entry",
	templateUrl: "./entry.component.html",
	styleUrls: ["./entry.component.scss"]
})
export class EntryComponent {
	constructor(public router: Router) {}
}