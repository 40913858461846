<div class='label-text'>
  <mat-card-title style="margin-bottom: 0;">
    {{ message | titlecase }} {{ alert_type | titlecase }}
  </mat-card-title>
</div>

<mat-card appearance="outlined">
  <div class='body-text'>
    <p style="font-weight: 500; margin-bottom: 1.5rem; font-size: 1.4rem;"> You are going to {{ message }} an {{ alert_type | titlecase }}.
      Please write your reason.
    </p>

    <div>
      <mat-label>Message</mat-label>
    </div>
    <div>
      <mat-form-field style="width: 104%;">
        <!-- <mat-label>Favorite Animal</mat-label> -->
        <!-- <input class="form-control mat-element" matInput [(ngModel)]="acknowledge_reason"> -->
        <textarea class="form-control" style="width: 100%; " matInput [(ngModel)]="acknowledge_reason"></textarea>
      </mat-form-field>
    </div>

    <mat-dialog-actions align="end" style="padding-right: 0">
      <button style="margin-bottom: 20px;" mat-button class="cancel-button" [mat-dialog-close]="'cancel'" tabindex="-1" [mat-dialog-close]="'cancel'">
        Cancel
      </button>
      <button style="margin-bottom: 20px;" mat-button class="ack-button" [mat-dialog-close]="acknowledge_reason" tabindex="-1"> {{ confirmButtonText }}</button>
    </mat-dialog-actions>
  </div>
</mat-card>

