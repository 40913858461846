import { Injectable, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ResourceService } from './resource.service';

export enum ENTRY {
  RECONNECT = 'reconnect',
  USER = 'user',
  APPLICATION = 'application',
  APP_TEMPLATE = 'app_template',
  APP_SERVICE = 'app_service',
  SERVICE = 'service',
  K8S_TEMPLATE = 'k8s_template',
  UCPE = 'ucpe',
  CONTROLLER = 'controller',
  CUSTOMER = 'customer',
  ACTION = 'action',
  ALERT = 'alert',
  EVENT_NODE = 'event_node',
  HWTYPE = 'hwtype',
  K8S_SERVICE = 'k8s_service',
  K8S_IMAGE = 'k8s_image',
  K8S_NAMESPACE = 'k8s_namespace',
  OPERATOR = 'operator',
  PROJECT = 'project',
  SITE = 'site',
  CONTROLLER_EVENT = 'controller_event',
  EVENT_K8S_SERVICE = 'event_k8s_service',
  SYSTEM_EVENT = 'system_event',
  UCPE_TEMPLATE = 'ucpe_template',
  LICENSE = 'license',
  OS_IMAGE = 'os_image',
  OS_FLAVOR = 'os_flavor',
  OS_NETWORK = 'os_network',
  OS_TEMPLATE = 'os_template',
  OS_SERVICE = 'os_service',
  BACKUP_PROFILE = 'backup_profile',
  K8S_SERVICE_BACKUP = 'k8s_service_backup',
  K8S_SERVICE_RESTORE = 'k8s_service_restore',
  HELM = 'helm',
  EVENT_HELM_CHART = 'event_helm_chart',
}

@Injectable({
  providedIn: 'root'
})
export class SseService {
  open = true;
  liveModeOpen = true;
  evtSource: EventSource;
  private es$$ = new Subject<string>();
  readonly es$ = this.es$$.asObservable();
  private user: number;

  constructor(private resourceService: ResourceService, private cookieService: CookieService, private router: Router, private ngZone: NgZone) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd && !this.open))
      .subscribe(() => [this.open, this.liveModeOpen] = [true, true]);
    window.addEventListener('beforeunload', () => {
      if (this.evtSource) {
        this.evtSource.close();
        console.log('EventSource closed because of page refresh');
      }
    });
  }

  reconnectSE(evs: Event) {
    console.log('event source err', evs);
    this.evtSource.close();
    setTimeout(() => {
      this.es$$.next(ENTRY.RECONNECT);
      this.initEventSourceSingleton();
    }, 2000);
  }

  initEventSourceSingleton() {
    if (!this.cookieService.get('user')) {
      return;
    }
    this.user = +this.cookieService.get('is_super_user') === 1 ? 0 : +this.cookieService.get('project_id');
    this.evtSource = new EventSource(`${this.resourceService.getApiUrl()}/notify/${this.user || 0}`);
    this.evtSource.onmessage = (message) => {
      this.ngZone.run(() => {
        console.log('updated', message);
        if ((this.open && this.liveModeOpen) || message.data.split(',').includes('alert') || message.data.split(',').includes('action')) {
          this.es$$.next(message.data.split(','));
        }
      });
    };
    this.evtSource.onerror = (evs) => this.reconnectSE(evs);
  }

  onSelectRow(event: any) {
    if (!event.length && !this.open) {
      this.open = true;
    }

    if (event.length === 1 && this.open) {
      this.open = false;
    }
  }
}
