<mat-sidenav-container autosize>
  <mat-sidenav #sidenav mode="side" opened="isExpanded" class="sidenav" disableClose>

    <mat-nav-list>

      <div class="sidebar-header-container" style="{{!isExpanded ? 'padding: 0;' : 'padding: 0 1.6rem'}}">
        <div style="{{!isExpanded ? 'display: none;' : 'display: flex; gap: 1rem; flex: 1; cursor: pointer;'}}" (click)="navigateToDashboard()">
          <img [src]="logPath" alt="Logo" width="24">

          <h1 class="title">{{ themeService.title || 'Edge' }} Management and Orchestration</h1>
        </div>

        <mat-button mat-flat-button (click)="isExpanded = !isExpanded" class="expand-button" [matTooltipShowDelay]="800" matTooltipClass="tooltipLook" matTooltip="{{isExpanded ? 'Close menu' : 'Open menu'}}">
          <mat-icon mat-list-icon class="chevron-button" [ngClass]="{ rotated: isExpanded }">chevron_right</mat-icon>
        </mat-button>
      </div>

      <mat-divider></mat-divider>
      
      <div class="menu-container">
        <div *ngFor="let item of items">
          <mat-list-item (click)="onItemSelected(item)" [matTooltipShowDelay]="800" matTooltipClass="tooltipLook" matTooltip="{{ isExpanded ? '' : item.name }}">
            <div class="menu-wrapper">
              <mat-icon mat-list-icon aria-hidden="false" [svgIcon]="item.svgIcon || ''">{{ item.icon }}</mat-icon>
              <span *ngIf="isExpanded">{{ item.name }}</span>
            </div>
          </mat-list-item>
          <div class="submenu" *ngIf="isExpanded && item.showSubmenu && item.children">
            <ng-container *ngFor="let child of item.children">
              <a *ngIf="child.name | ifRole" (click)="onItemChildren(item, child)"
                [class.active]="child.theChosenChild" mat-list-item>{{ child.name }}</a>
            </ng-container>
          </div>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="sidenav-footer-container">
			  <app-alerts-dropdown [isExpanded]="isExpanded"></app-alerts-dropdown>
			  <telco-user-operations-dropdown [isExpanded]="isExpanded"></telco-user-operations-dropdown>
        <app-login-dropdown [isExpanded]="isExpanded"></app-login-dropdown>
      </div>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar #toolbarRef>
      <telco-home-tabs [tabs$]="tabs$"></telco-home-tabs>
    </mat-toolbar>

    <div class="content">
      <ng-template #separatorTemplate>
        <mat-icon [ngStyle]="{'color': 'black'}">arrow_right</mat-icon>
      </ng-template>

      <div class="breadcrumbs-container" *ngIf="displayBreadcrumb">
        <xng-breadcrumb [separator]="separatorTemplate"></xng-breadcrumb>
      </div>

      <mat-divider></mat-divider>

      <router-outlet />
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>