import { ROUTE_CONFIGS } from "src/app/models/constants/route-paths.constants";

export const DASHBOARD_ROUTE_CONFIGS = {
	OPER_STATUS_EDGES: { title: ROUTE_CONFIGS.EDGE_DEVICES.title, route: `${ROUTE_CONFIGS.EDGE_DEVICES.route}/:name/:chartName` },
	OPER_STATUS_CONTROLLERS: { title: ROUTE_CONFIGS.EDGE_DEVICES.title, route: `${ROUTE_CONFIGS.CONTROLLERS.route}/Controller/:name/:chartName` },
	EDGE_EVENTS: { title: ROUTE_CONFIGS.NOTIFICATIONS_EDGE_EVENTS.title, route: `${ROUTE_CONFIGS.NOTIFICATIONS_EDGE_EVENTS.route}/:severity/:nodeSerial/:nodeId/:activeOnly` },
	CONTROLLER_EVENTS: { title: ROUTE_CONFIGS.NOTIFICATIONS_CONTROLLER_EVENTS.title, route: `${ROUTE_CONFIGS.NOTIFICATIONS_CONTROLLER_EVENTS.route}/:severity/:controllerHostname/:controllerId` },
	ALERTS: { title: ROUTE_CONFIGS.NOTIFICATIONS_ALERTS.title, route: `${ROUTE_CONFIGS.NOTIFICATIONS_ALERTS.route}` },
	ADMIN_STATUSES_EDGES: { title: ROUTE_CONFIGS.EDGE_DEVICES.title, route: `${ROUTE_CONFIGS.EDGE_DEVICES.route}/:name/:chartName` },
	APP_EDGE_SERVICE_ADMIN_STATUS: { title: ROUTE_CONFIGS.APPLICATION_EDGE_SERVICES.title, route: `${ROUTE_CONFIGS.APPLICATION_EDGE_SERVICES.route}/:name` },
	EDGE_DISCOVERY: { title: ROUTE_CONFIGS.DISCOVERY_EDGE_DEVICES.title, route: `${ROUTE_CONFIGS.DISCOVERY_EDGE_DEVICES.route}/:name` },
	CONTROLLER_DISCOVERY: { title: ROUTE_CONFIGS.DISCOVERY_CONTROLLERS.title, route: `${ROUTE_CONFIGS.DISCOVERY_CONTROLLERS.route}/:name` },
	EDGE_SERVICE_TEMPLATE: { title: ROUTE_CONFIGS.KUBERNETES_EDGE_SERVICES.title, route: `${ROUTE_CONFIGS.KUBERNETES_EDGE_SERVICES.route}/:state/:name` },
	K8S_EDGE_SERVICE_ADMIN_STATUS: { title: ROUTE_CONFIGS.KUBERNETES_EDGE_SERVICES.title, route: `${ROUTE_CONFIGS.KUBERNETES_EDGE_SERVICES.route}/:state/:name` },
	K8S_SERVICE_EVENTS: { title: ROUTE_CONFIGS.NOTIFICATIONS_KUBERNETES_SERVICE_EVENTS.title, route: `${ROUTE_CONFIGS.NOTIFICATIONS_KUBERNETES_SERVICE_EVENTS.route}/:severity/:controllerHostname/:controllerId` },
	OS_SERVICE_EVENTS: { title: ROUTE_CONFIGS.NOTIFICATIONS_OPENSTACK_SERVICE_EVENTS.title, route: `${ROUTE_CONFIGS.NOTIFICATIONS_OPENSTACK_SERVICE_EVENTS.route}` }
}