import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subscription, iif, of } from 'rxjs';
import { delay, filter, map, retryWhen, switchMap, tap } from 'rxjs/operators';
import { AlarmsAndEventsService } from 'services/alarmsAndEvents.service';
import { AlertNotificationService } from 'services/alertNotification.service';
import { ENTRY, SseService } from 'services/sse.service';
import { AcknowledgeDialogComponent } from 'src/app/components/custom-components/acknowledge-dialog/acknowledge-dialog.component';
import { exhaustMapWithTrailing } from 'src/app/components/helpers/rxjs.exhaustmap.with.trailing';
import { CheckBoxProps, PaginatorInfo, Sorted } from 'src/app/models';
import { SelectionItem } from 'src/app/models/interfaces/SelectionItem';
import { TableDataResult } from 'src/app/models/interfaces/dataTableResult';
import { CustomDropdownTableComponent } from '../custom-dropdown-table/custom-dropdown-table.component';

const DEFAULT_SORT_ORDER = 'create_time';
const DEFAULT_SORT_DIRECTION = 'desc';

@Component({
  selector: 'telco-user-operations-dropdown',
  templateUrl: './user-operations-dropdown.component.html',
  styleUrls: [ './user-operations-dropdown.component.scss' ]
})
export class UserOperationsDropdownComponent implements OnInit, OnDestroy {
  @ViewChild(CustomDropdownTableComponent) customDropdownTableComponent;

  pageCount: number = 0;
  tableData$: any[];
  severity;
  initialColumns = [ 'select', 'create_time', 'username', 'status', 'description' ];
  isAllChecked: boolean = false;
  paginatorInfo: PaginatorInfo;
  filters = [];
  currentSort: Sorted;
  selectedItems: SelectionItem[];
  showAckBtn: boolean = true;
  private $$sseStream: Observable<any>;
  private $$sseStreamSubscription: Subscription;

  @Input() isExpanded;

  constructor(
    private alarmsAndEventService: AlarmsAndEventsService,
    private cookies: CookieService,
    private sseService: SseService,
    private dialog: MatDialog,
    private changeDetectorRefs: ChangeDetectorRef,
    private alertNotificationService: AlertNotificationService) {
    this.currentSort = new Sorted();
    this.currentSort.order = DEFAULT_SORT_ORDER;
    this.currentSort.direction = DEFAULT_SORT_DIRECTION;
  }

  ngOnDestroy() {
    this.$$sseStreamSubscription.unsubscribe();
  }

  ngOnInit(): void {
    if (this.cookies.get('is_readonly') == 'true') {
      this.showAckBtn = false;
    }

    this.refreshTable();
    this.$$sseStream = this.sseService.es$.pipe(
      filter((x: any) => x.includes(ENTRY.ACTION) || x.includes(ENTRY.RECONNECT)),
      exhaustMapWithTrailing(() => this.eventSourceRefreshTable()),
    );
    this.$$sseStreamSubscription = this.$$sseStream.pipe(
      retryWhen(errors => errors.pipe(delay(10000))),
    ).subscribe();
  }

  eventSourceRefreshTable(): Observable<any> {
    return this.alarmsAndEventService.getUserActionsAdvanced(this.paginatorInfo?.pageIndex + 1 || 1,
      this.paginatorInfo?.pageSize || 15, this.currentSort?.order || 'username', this.currentSort?.direction || 'asc', true, [ {
        columnName: 'Active',
        filterValue: 'true'
      } ]).pipe(
      map((result: TableDataResult) => {
        console.log('updated from SSE event', ENTRY.ACTION);
        this.tableData$ = result.data;
        this.pageCount = result.count;
        this.changeDetectorRefs.detectChanges();
      })
    );
  }

  refreshTable() {
    this.alarmsAndEventService.getUserActionsAdvanced(this.paginatorInfo?.pageIndex + 1 || 1,
      this.paginatorInfo?.pageSize || 15, this.currentSort?.order || 'username', this.currentSort?.direction || 'asc', true, [ {
        columnName: 'Active',
        filterValue: 'true'
      } ]).subscribe((res: TableDataResult) => {
      this.tableData$ = res.data;
      this.pageCount = res.count;
    });
  }

  getSelectedElement(event: any) {
    this.sseService.onSelectRow(event);

    this.selectedItems = event;
  }

  onSortChanged(event) {
    this.currentSort == undefined ? this.currentSort = new Sorted : null;
    this.currentSort.order = event[2];
    this.currentSort.direction = event[3] != '' ? event[3] : 'asc';
    this.refreshTable();
  }


  isAllCheckboxCheckedControl(event: CheckBoxProps) {
    this.isAllChecked = event.isAllChecked;
  }

  cancelAction() {
    if (this.selectedItems.length > 0) {
      const isAllChecked = this.isAllChecked;
      const selectedItems = this.selectedItems;

      this.alertNotificationService.alertConfirm('You are going to cancel user operation.Are you sure?').pipe(
        filter(res => res['result'] == 'confirm'),
        switchMap((): any => {
          return iif(() => isAllChecked,
            this.alarmsAndEventService.getBulkUserOperationIds(true, [ { columnName: 'Active', filterValue: 'true' } ])
              .pipe(
                map(ids => {
                  return { ids };
                })
              ),
            of({
              ids: {
                id_list: selectedItems.map(item => {
                  return item.id;
                })
              }
            })
          );
        }),
        switchMap(res => {
          return this.alarmsAndEventService.doCancelUserOperations(1, 20, res['ids']['id_list']);
        }),
        tap(() => {
            this.customDropdownTableComponent?.clearTableSelection();
            this.refreshTable();
          }
        )
      ).subscribe();
    }
  }

  acknowledgeAlarm() {
    if (this.selectedItems.length > 0) {
      const isAllChecked = this.isAllChecked;
      const selectedItems = this.selectedItems;
      this.openAcknowledgeDialog('acknowledge', 'user operation', 'Acknowledge', '').pipe(
        filter(res => res != 'cancel'),
        switchMap((reason): any => {
          return iif(() => isAllChecked,
            this.alarmsAndEventService.getBulkUserOperationIds(true, [ { columnName: 'Active', filterValue: 'true' } ])
              .pipe(
                map(ids => {
                  return { reason, ids };
                })
              ),
            of({
              reason: reason, ids: {
                id_list: selectedItems.map(item => {
                  return item.id;
                })
              }
            })
          );
        }),
        switchMap(res => {
          return this.alarmsAndEventService.doAcknowledgeUserOperations(1, 20, res['ids']['id_list'], res['reason']);
        }),
        tap(() => {
            this.customDropdownTableComponent.clearTableSelection();
            this.refreshTable();
          }
        )
      ).subscribe();
    }
  }

  public openAcknowledgeDialog(message: string, alert_type: string, confirm?: string, cancel?: string): Observable<any> {
    if (this.dialog.openDialogs.length > 0) {
      return;
    }

    const dialogRef = this.dialog.open(
      AcknowledgeDialogComponent,
      {
        data: {
          message,
          alert_type,
          buttonText: { confirm, cancel },
        }, disableClose: true
      });

    return dialogRef.afterClosed();
  }

  onPageChanged(event) {
    this.paginatorInfo = event;
    this.refreshTable();
  }
}
