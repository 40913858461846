import { DragDropModule } from '@angular/cdk/drag-drop';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { CookieService } from 'ngx-cookie-service';
import { NgxEchartsModule } from 'ngx-echarts';
import { TourService } from 'ngx-ui-tour-md-menu';
import { AlarmsAndEventsService } from 'services/alarmsAndEvents.service';
import { AlertNotificationService } from 'services/alertNotification.service';
import { AppConfigService, configFactory } from 'services/app-config.service';
import { ApplicationService } from 'services/application.service';
import { AuthenticationService } from 'services/authentication.service';
import { ControllerService } from 'services/controller.service';
import { CustomerService } from 'services/customer.service';
import { HardwareTypeService } from 'services/hardwaretype.service';
import { KuberService } from 'services/kuberService.service';
import { OperatorService } from 'services/operator.service';
import { ProjectService } from 'services/project.service';
import { ResourceService } from 'services/resource.service';
import { ServiceService } from 'services/service.service';
import { SiteService } from 'services/site.service';
import { StorageService } from 'services/storage.service';
import { UserService } from 'services/user.service';
import { UtilsService } from 'services/utils.service';
import { BreadcrumbModule, BreadcrumbService } from 'xng-breadcrumb';
import { routing } from './app-routing.module';
import { AppComponent } from './app.component';
import { ActiveOperationsChartComponent } from './components/charts/active-operations-chart/active-operations-chart.component';
import { AdminStatusChartComponent } from './components/charts/admin-status-chart/admin-status-chart.component';
import { AlarmsChartComponent } from './components/charts/alarms-chart/alarms-chart.component';
import { AlertsChartComponent } from './components/charts/alerts-chart/alerts-chart.component';
import { ControllerEventChartComponent } from './components/charts/controller-event-chart/controller-event-chart.component';
import { DeployedServAdminChartComponent } from './components/charts/deployed-serv-admin-chart/deployed-serv-admin-chart.component';
import { DeployedServicesChartComponent } from './components/charts/deployed-services-chart/deployed-services-chart/deployed-services-chart.component';
import { DiscoveryChartComponent } from './components/charts/discovery-chart/discovery-chart.component';
// import { MultiCpuChartComponent } from './components/charts/multi-cpu-chart/multi-cpu-chart.component';
import { StatusChartComponent } from './components/charts/status-chart/status-chart.component';
import { UcpeChartsComponent } from './components/charts/ucpe-charts/ucpe-charts.component';
import { VnfTypesChartComponent } from './components/charts/vnf-types-chart/vnf-types-chart.component';
import { AcknowledgeDialogComponent } from './components/custom-components/acknowledge-dialog/acknowledge-dialog.component';
import { AlertDialogComponent } from './components/custom-components/alert-dialog/alert-dialog.component';
import { DiscoveryDialogComponent } from './components/custom-components/discovery-dialog/discovery-dialog.component';
import { EditorModule } from './components/custom-components/editor/editor.module';
import { ListDialogComponent } from './components/custom-components/list-dialog/list-dialog.component';
import { LoadingComponent } from './components/custom-components/loading/loading.component';
import { VisElementsComponent } from './components/custom-components/ng-vis/helpers/vis-elements/vis-elements.component';
import { DatePickerDialogComponent } from './components/custom-components/telco-date-picker/date-picker-dialog/date-picker-dialog.component';
import { VmListDialogComponent } from './components/custom-components/vm-list-dialog/vm-list-dialog.component';
import { AuthGuard } from './components/guards/auth.guard';
import { ErrorInterceptor } from './components/helpers/error.interceptor';
import { JwtInterceptor } from './components/helpers/jwt.interceptor';
import { StatusInterceptor } from './components/helpers/status.interceptor';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { LoginComponent } from './components/pages/login/login.component';
import { DnsTestComponent } from './components/pages/troubleshooting/dns-test/dns-test.component';
import { InterfaceTableComponent } from './components/pages/troubleshooting/interface-table/interface-table.component';
import { LogsCaptureComponent } from './components/pages/troubleshooting/logs-capture/logs-capture.component';
import { LteTestComponent } from './components/pages/troubleshooting/lte-test/lte-test.component';
import { PingTestComponent } from './components/pages/troubleshooting/ping-test/ping-test.component';
import { ProcessAnalysisComponent } from './components/pages/troubleshooting/process-analysis/process-analysis.component';
import { RoutingTableComponent } from './components/pages/troubleshooting/routing-table/routing-table.component';
import { ServicesControlComponent } from './components/pages/troubleshooting/services-control/services-control.component';
import { SpeedTestComponent } from './components/pages/troubleshooting/speed-test/speed-test.component';
import { TcpDumpComponent } from './components/pages/troubleshooting/tcp-dump/tcp-dump.component';
import { TracerouteTestComponent } from './components/pages/troubleshooting/traceroute-test/traceroute-test.component';
import { TroubleshootingComponent } from './components/pages/troubleshooting/troubleshooting.component';
import { WifiTestComponent } from './components/pages/troubleshooting/wifi-test/wifi-test.component';
import { DashboardMobileComponent } from './mobile/components/pages/dashboard/dashboard-mobile.component';
import {
  ApplicationCatalogEdgeComponent
} from './modules/EdgeManagement/Devices/applications-catalog-edge/application-catalog-edge/application-catalog-edge.component';
import { ApplicationsCatalogEdgeComponent } from './modules/EdgeManagement/Devices/applications-catalog-edge/applications-catalog-edge.component';
import { EdgeTemplatesComponent } from './modules/EdgeManagement/edge-templates/edge-templates/edge-templates.component';

import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ServiceDesignerService } from 'services/serviceDesigner.service';
import { environment } from 'src/environments/environment';
import { CustomizeBarComponent } from './components/UI/customize-bar/customize-bar.component';
import { HomeTabsComponent } from './components/UI/home-tabs/home-tabs.component';
import { AlertsDropdownComponent } from './components/UI/navbar/alerts-dropdown/alerts-dropdown.component';
import { CustomDropdownTableComponent } from './components/UI/navbar/custom-dropdown-table/custom-dropdown-table.component';
import { LoginDropdownComponent } from './components/UI/navbar/login-dropdown/login-dropdown.component';
import { NavbarComponent } from './components/UI/navbar/navbar.component';
import { UserOperationsDropdownComponent } from './components/UI/navbar/user-operations-dropdown/user-operations-dropdown.component';
import { IfRolePipe } from './components/UI/sidebar/ifRole.pipe';
import { SidebarComponent } from './components/UI/sidebar/sidebar.component';
import { AlertDialogGuardComponent } from './components/custom-components/alert-dialog-guard/alert-dialog-guard.component';
import { ClusterStatusComponent } from './components/custom-components/cluster-status/cluster-status.component';
import { DeviceDetailsComponent } from './components/custom-components/device-details/device-details.component';
import { ListObjectDialogComponent } from './components/custom-components/list-object-dialog/list-object-dialog.component';
import { EntryComponent } from './components/entry/entry.component';
import { DisableSaveInterceptor } from './components/helpers/disable-save.interceptor';
import { LsmodComponent } from './components/pages/troubleshooting/lsmod/lsmod.component';
import { LspciComponent } from './components/pages/troubleshooting/lspci/lspci.component';
import { CustomReuseStrategy } from './custom-reuse-strategy';
import { ClearSelectionDirective } from './directive/clear-selection.directive';
import { SafePipe } from './directive/safe.directive';
import { MobileAlertsDropdownComponent } from './mobile/components/UI/mobile-navbar/alerts-dropdown/alerts-dropdown-mobile.component';
import { MobileLoginDropdownComponent } from './mobile/components/UI/mobile-navbar/login-dropdown/login-dropdown-mobile.component';
import { MobileNavbarComponent } from './mobile/components/UI/mobile-navbar/navbar-mobile.component';
import { SidenavMobileComponent } from './mobile/components/UI/sidenav-mobile/sidenav-mobile.component';
import { MobileActiveOperationsChartComponent } from './mobile/components/charts/active-operations-chart/mobile-active-operations-chart.component';
import { MobileAlarmsChartComponent } from './mobile/components/charts/alarms-chart/mobile-alarms-chart.component';
import { MobileAlertsChartComponent } from './mobile/components/charts/alerts-chart/mobile-alerts-chart.component';
import { MobileControllerEventChartComponent } from './mobile/components/charts/controller-event-chart/mobile-controller-event-chart.component';
import { MobileUcpeChartsComponent } from './mobile/components/charts/ucpe-charts/mobile-ucpe-charts.component';
import { MobileEntryComponent } from './mobile/components/mobile-entry/entry-mobile.component';
import { DescriptionModalComponent } from './mobile/mobile-custom-table/description-modal/description-modal.component';
import { ControllerStateComponent } from './modules/ControllerManagement/controller-state/controller-state.component';
import { InterfaceNamePipe } from './modules/EdgeManagement/Devices/ucpe-config/edge-config-step2/interface-name.pipe';
import { DashboardService } from './services';
import { SharedModule } from './shared/shared.module';
import { TabEffects } from './store/effects/tab.effects';
import { reducer } from './store/reducers/tab.reducer';



@NgModule({
  declarations: [
    AppComponent,
    LoginDropdownComponent,
    MobileLoginDropdownComponent,
    DatePickerDialogComponent,
    NavbarComponent,
    MobileNavbarComponent,
    SidebarComponent,
    HomeTabsComponent,
    DashboardComponent,
    DashboardMobileComponent,
    UcpeChartsComponent,
    AlertsDropdownComponent,
    MobileAlertsDropdownComponent,
    CustomizeBarComponent,
    VnfTypesChartComponent,
    AlarmsChartComponent,
    StatusChartComponent,
    TroubleshootingComponent,
    PingTestComponent,
    TracerouteTestComponent,
    DnsTestComponent,
    LogsCaptureComponent,
    LteTestComponent,
    WifiTestComponent,
    ServicesControlComponent,
    RoutingTableComponent,
    InterfaceTableComponent,
    TcpDumpComponent,
    SpeedTestComponent,
    LoginComponent,
    AlertDialogComponent,
    ApplicationsCatalogEdgeComponent,
    ApplicationCatalogEdgeComponent,
    ProcessAnalysisComponent,
    DiscoveryChartComponent,
    AdminStatusChartComponent,
    VisElementsComponent,
    AcknowledgeDialogComponent,
    ListDialogComponent,
    ClearSelectionDirective,
    VmListDialogComponent,
    ListObjectDialogComponent,
    SafePipe,
    DeployedServicesChartComponent,
    DeployedServAdminChartComponent,
    AlertsChartComponent,
    UserOperationsDropdownComponent,
    CustomDropdownTableComponent,
    LoadingComponent,
    IfRolePipe,
    DiscoveryDialogComponent,
    ControllerEventChartComponent,
    ActiveOperationsChartComponent,
    EdgeTemplatesComponent,
    // MultiCpuChartComponent,
    ClusterStatusComponent,
    ControllerStateComponent,
    EntryComponent,
    MobileEntryComponent,
    DescriptionModalComponent,
    SidenavMobileComponent,
    MobileUcpeChartsComponent,
    MobileAlarmsChartComponent,
    MobileControllerEventChartComponent,
    MobileAlertsChartComponent,
    MobileActiveOperationsChartComponent,
    LspciComponent,
    LsmodComponent,
    DeviceDetailsComponent,
    AlertDialogGuardComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    EditorModule,
    FormsModule,
    HttpClientJsonpModule,
    routing,
    NgxEchartsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    RouterModule,
    KeyboardShortcutsModule,
    BreadcrumbModule,
    MatProgressSpinnerModule,
    DragDropModule,
    StoreModule.forRoot({ tabs: reducer }, {
      runtimeChecks: {
        // This causes the error:
        //__NG_ELEMENT_ID__, object is not extensible at bloomAdd
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
    }),
    EffectsModule.forRoot([TabEffects]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [AppConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StatusInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DisableSaveInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },

    // will be reverted for further investigations
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: LoadingInterceptor,
    //   multi: true,
    // },
    AuthGuard,
    DashboardService,
    ResourceService,
    AuthenticationService,
    CookieService,
    OperatorService,
    ProjectService,
    UserService,
    CustomerService,
    SiteService,
    ServiceService,
    HardwareTypeService,
    BreadcrumbService,
    AlertNotificationService,
    ApplicationService,
    AlarmsAndEventsService,
    StorageService,
    UtilsService,
    ControllerService,
    KuberService,
    TourService,
    ServiceDesignerService,
    InterfaceNamePipe
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
