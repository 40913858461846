import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild
} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import {
  MatColumnDef,
  MatHeaderRowDef,
  MatNoDataRow,
  MatRowDef,
  MatTable
} from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SseService } from 'services/sse.service';
import { ThemeService } from 'services/theme.service';
import { GenericModel } from '../../../directive/clear-selection.directive';
import { PaginatorInfo } from '../../../models';

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: [ './custom-table.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomTableComponent<T> implements AfterContentInit, OnInit, OnDestroy {
  @ViewChild('checkboxHeader') checkboxHeader: MatCheckbox;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('scroll') scroll: ElementRef;
  @ContentChildren(MatHeaderRowDef) headerRowDefs: QueryList<MatHeaderRowDef>;
  @ContentChildren(MatRowDef) rowDefs: QueryList<MatRowDef<T>>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;
  @ContentChild(MatNoDataRow) noDataRow: MatNoDataRow;
  @ViewChild(MatTable, { static: true }) table: MatTable<T>;
  @Input() initialColumns: string[] = [];
  @Input() tableName = '';
  @Input() pageCount;
  @Input() width = '';
  @Input() currentPage: PaginatorInfo;
  @Output() defaultRefresh = new EventEmitter<object[]>();
  @Output() newItemEvent = new EventEmitter<object[]>();
  @Output() allItemCheckedEvent = new EventEmitter<{ isAllChecked: boolean; }>();
  @Output() pageChangeEvent = new EventEmitter<PaginatorInfo>();
  selection = new SelectionModel<GenericModel>(true, [], true, (c1, c2) => {
    if (this.tableName == 'DeployedServices') {
      return c1.k8s_service_id === c2.k8s_service_id;
    }
    if (this.tableName == 'OpenstackDeployedServices') {
      return c1.os_service_id === c2.os_service_id;
    }
    if (this.tableName == 'ApplicationManageCatalog') {
      return c1.app_service_id === c2.app_service_id;
    }
    if (this.tableName == 'ControllerDiscovery') {
      return c1.hostname === c2.hostname;
    }
    if (c1.id) {
      return c1.id === c2.id;
    }
    if (c1.uid) {
      return c1.uid === c2.uid;
    }

  });

  $destroyed: Subject<boolean> = new Subject<boolean>();
  private _dataSource: any;
  get dataSource(): any {
    return this._dataSource;
  }

  @Input()
  set dataSource(value: any) {
    if (this.sseService.open) {
      if (this.checkboxHeader) {
        (this.checkboxHeader as any).checked = false;
      }
      this._dataSource = value;
    }
  }
  constructor(private sseService: SseService, private themeService: ThemeService, private el: ElementRef) {
  }
  toggle(item: { id: number; }): void {
    const newItemWithSameKey = { ...item };
    if (this.selection.compareWith && this.selection.isSelected(newItemWithSameKey)) {
      const matchingRow = this.selection.selected.find((selectedRow) => this.selection.compareWith!(selectedRow, newItemWithSameKey));
      if (matchingRow) {
        this.selection.toggle(matchingRow);
      }
    } else {
      this.selection.toggle(newItemWithSameKey);
    }
  }

  ngOnInit(): void {
    this.selection.changed.pipe(takeUntil(this.$destroyed)).subscribe(() => {
      this.sseService.onSelectRow(this.selection.selected);
      this.newItemEvent.emit(this.selection.selected);
    });
    this.themeService.selectedInTable.pipe(takeUntil(this.$destroyed)).subscribe(color => this.el.nativeElement.style.setProperty('--active', color));
  }

  ngAfterContentInit(): void {
    this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
    this.rowDefs.forEach(rowDef => this.table.addRowDef(rowDef));
    this.headerRowDefs.forEach(headerRowDef => this.table.addHeaderRowDef(headerRowDef));
    this.table.setNoDataRow(this.noDataRow);
  }

  isAllSelectedInPage(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this._dataSource.length;
    return numSelected === numRows;
  }

  toggleAllRows(ev) {
    this.sseService.open = !ev.checked;
    this.allItemCheckedEvent.emit({ isAllChecked: ev.checked });
    if (this.isAllSelectedInPage() && !ev.checked) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this._dataSource);
  }

  onPageStore(newPage) {
    if (newPage) {
      this.pageCount = newPage.length;
      this.handlePageEvent(newPage);
    } else {
      this.defaultRefresh.emit(null);
    }
  }

  handlePageEvent(event: PaginatorInfo): void {
    if (this.selection.hasValue()) {
      this.clearTableSelection();
      this.allItemCheckedEvent.emit({ isAllChecked: false });
    }
    setTimeout(() => {
      this.currentPage = { ...event };
      this.pageChangeEvent.emit(this.currentPage);
    }, 0);
    this.scroll.nativeElement.scrollTop = 0;
  }

  rowClicked(row) {
    this.toggle(row);
    (this.checkboxHeader as any).checked = this.pageCount!=1 && this.selection.selected.length == this.pageCount;
    this.allItemCheckedEvent.emit({ isAllChecked: this.checkboxHeader.checked });
  }

  clearTableSelection() {
    this.selection.clear();
  }

  CbChange(row) {
    this.rowClicked(row);
  }

  resetPaginator() {
    this.paginator.firstPage();
  }

  ngOnDestroy(): void {
    this.$destroyed.next(true);
    this.$destroyed.unsubscribe();
  }
}
