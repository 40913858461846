import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { CpuChartData, RamChartData, HddChartData, AlarmsChartData, VnfChartData, EdgeStatuses, DiscoveryChartData } from '../models';
import { ServicesChartData } from 'src/app/models/ServicesChartData';
import { ManagerHealthChartData } from '../models/ManagerHealthChartData';
import { ResourceService } from './resource.service';
import { AlertsChartData } from '../models/interfaces/AlertsChartData';

export class ServicesAllChartData {
  'not started': number;
  'started': number;
}

@Injectable({
  providedIn: 'root'
})

export class DashboardService {
  constructor(private resourceService: ResourceService, private http: HttpClient
  ) {
  }

  public loadControllerChartData(): Observable<EdgeStatuses> {
    return this.http.get<EdgeStatuses>(this.resourceService.getApiUrl() + '/dashboard/controller_status')
  }

  public loadUcpeChartData(): Observable<any> {
    // return of({    unlicensed:5,
    //   upgrading:6,
    //   'not contacted':8,
    //   stopping:100});
    return this.http.get<EdgeStatuses>(this.resourceService.getApiUrl() + '/dashboard/status')

  }

  public loadAlarmChartData(): Observable<AlarmsChartData> {
    return this.http.get<AlarmsChartData>(this.resourceService.getApiUrl() + '/dashboard/node/events').pipe(shareReplay())
  }

  public loadAlertChartData(): Observable<AlertsChartData> {
    return this.http.get<AlertsChartData>(this.resourceService.getApiUrl() + '/dashboard/alerts').pipe(shareReplay())
  }

  public loadContrlActiveEventsChartData(): Observable<AlertsChartData> {
    return this.http.get<AlertsChartData>(this.resourceService.getApiUrl() + '/dashboard/controller/events');
  }
  public loadK8sServiceEventsChartData(): Observable<AlertsChartData> {
    return this.http.get<AlertsChartData>(this.resourceService.getApiUrl() + '/dashboard/k8s_service/events');
  }
  public loadHelmServiceEventsChartData(): Observable<AlertsChartData> {
    return this.http.get<AlertsChartData>(this.resourceService.getApiUrl() + '/dashboard/helm/events');
  }
  public loadOsServiceEventsChartData(): Observable<AlertsChartData> {
    return this.http.get<AlertsChartData>(this.resourceService.getApiUrl() + '/dashboard/os_service/events');
  }
  public loadActiveOpersChartData(): Observable<AlertsChartData> {
    return this.http.get<AlertsChartData>(this.resourceService.getApiUrl() + '/dashboard/actions');
  }

  public loadDiscoveryChartData(): Observable<DiscoveryChartData> {
    return this.http.get<DiscoveryChartData>(this.resourceService.getApiUrl() + '/dashboard/status/discover')
  }

  public loadControllerDiscoveryChartData(): Observable<DiscoveryChartData> {
    return this.http.get<DiscoveryChartData>(this.resourceService.getApiUrl() + '/dashboard/status/controller/discover')
  }

  public loadAdminStatusChartData(): Observable<any> {
    // return of({
    //   "applying": 1,
    //   "not synced": 49726,
    //   "rebooting": 1,
    //   "synced": 49646,
    //   "unknown": 2,
    //   "test1": 2,
    //   "test2": 1000,
    //   "test3": 500,
    //   "test4": 2,

    //   })
   return this.http.get<any>(this.resourceService.getApiUrl() + '/dashboard/admin_status')
  }


  public loadVnfChartData(): Observable<VnfChartData> {
    return this.http.get<VnfChartData>(this.resourceService.getApiUrl() + '/dashboard/application/admin_status')
  }

  public loadServiceChartData(): Observable<ServicesChartData> {
    return this.http.get<ServicesChartData>(this.resourceService.getApiUrl() + '/dashboard/services/template');
  }

  public loadServiceAdminChartData(): Observable<ServicesAllChartData> {
    return this.http.get<ServicesAllChartData>(this.resourceService.getApiUrl() + '/dashboard/services/admin_status');
  }


  public loadManagerHealthChartData(): Observable<ManagerHealthChartData> {

    return of(
      {
        cpuLoad: Math.round(Math.random() * 100),
        hddLoad: Math.round(Math.random() * 100),
        ramLoad: Math.round(Math.random() * 100),
      }

    );
  }

  public loadCpuChartData(): Observable<CpuChartData> {
    return of({
      groupA: Math.round(Math.random() * 100),
      groupB: Math.round(Math.random() * 100),
      groupC: Math.round(Math.random() * 100),
      groupD: Math.round(Math.random() * 100)
    });
  }

  public loadRamChartData(): Observable<RamChartData> {
    return of({
      groupA: Math.round(Math.random() * 100)
    });
  }

  public loadHddChartData(): Observable<HddChartData> {
    return of({
      groupA: Math.round(Math.random() * 100),

    });
  }

  getSystemMonitoring(): Observable<any> {
    return this.http.get<any>(this.resourceService.getApiUrl() + '/monitor/sysmon')
  }
}
